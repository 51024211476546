import React, { useState, useEffect } from "react";
import ModalReserveMobile from "./ModalReserveMobile";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {createChat} from "../../../redux/reducers/UserSlice/UserSlice";
import { useDispatch } from "react-redux";
import { RootState, AppDispatch } from "redux/store";
import { useLocation, useNavigate } from "react-router-dom";
import ContactOwnerModal from "../listing-stay-detail/contact-owner";
import { useSelector } from 'react-redux';
import { PropertyToEdit } from "../../../redux/reducers/Properties/PropertiesSliceTypes";
import { getallupdateProperties } from "../../../redux/reducers/Properties/PropertiesSlice";
const MobileFooterSticky = () => {
  const [startDate] = useState<Date | null>(new Date("2023/02/06"));
  const [endDate] = useState<Date | null>(new Date("2023/02/23"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [propertyData, setPropertyData] = useState<PropertyToEdit | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { globalId } = useSelector((state: RootState) => state.property);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const propertyId = globalId || id;
  const router = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  // const handleChatButtonClick = async () => {
  //   try {
  //     await dispatch(createChat({ sender_id: 3, receiver_id: 1, property_id: 9 })).unwrap(); // `unwrap` throws an error if rejected
  //     router('/messages'); 
  //   } catch (error) {
  //     alert("Failed to create chat. Please try again."); 
  //   }
  // };
  

  const handleChatButtonClick: () => void = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleSendMessage = (data:any) => {
    console.log('Message sent:', data);
    setIsModalOpen(false); 
  };

  useEffect(() => {
    const fetchPropertyData = async () => {
      if (propertyId || id) {
        try {
          setLoading(true);
          const response: any = await dispatch(
            getallupdateProperties({ propertyId })
          );

          // Check for property data in response.payload
          const property = response.payload?.property;
          if (property) {
            setPropertyData(property);
          } else {
            console.error("Property not found:", response);
          }
        } catch (error) {
          console.error('Error fetching property data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPropertyData();
  }, [propertyId, id, dispatch]);

  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
      <div className="container flex items-center justify-between">
        <div className="">
          <span className="block text-xl font-semibold">
            $311
            <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
              /night
            </span>
          </span>
          <ModalSelectDate
            renderChildren={({ openModal }) => (
              <span
                onClick={openModal}
                className="block text-sm underline font-medium"
              >
                {converSelectedDateToString([startDate, endDate])}
              </span>
            )}
          />
        </div>
        <div className="flex space-x-2">
        <ModalReserveMobile
          renderChildren={({ openModal }) => (
            <ButtonPrimary
              sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
              onClick={openModal}
            >
              Reserve
            </ButtonPrimary>
            
          )}
          />
          <ButtonPrimary
          sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
           onClick={handleChatButtonClick} 
        >
         Contact Owner
         </ButtonPrimary>
         {isModalOpen && (
        <ContactOwnerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSend={handleSendMessage}
          propertyData={propertyData}
        />
      )}
      </div>
    </div>
    </div>
  );
};

export default MobileFooterSticky;
