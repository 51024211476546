import React, { FC, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "redux/store";
import {
  getallupdateProperties,
  updateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import FormItem from "./FormItem";
import FormInputNumber from "../../shared/FormInputNumber/FormInputNumber";
import CommonLayout from "./CommonLayout";
import { SizeValidationSchema } from "utils/formSchema";

export interface PageAddListing3Props { }

const PageAddListing3: FC<PageAddListing3Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const { globalId } = useSelector((state: RootState) => state.property);
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [initialValues, setInitialValues] = useState({
    acreage: "",
    guests: 0,
    bedroom: 0,
    // beds: 0,
    bathroom: 0,
    // kitchen: 0,
  });

  useEffect(() => {
    const propertyId = globalId || id;

    if (propertyId && id) {
      dispatch(getallupdateProperties({ propertyId }))
        .unwrap()
        .then((response) => {
          if (response?.success && response.property) {
            setInitialValues({
              acreage: response.property.property_size || "",
              guests: response.property.guest_number || 0,
              bedroom: response.property.total_rooms || 0,
              // beds: response.property.beds || 0,
              bathroom: response.property.total_bathrooms || 0,
              // kitchen: response.property.kitchen || 0,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching property data:", error);
        });
    }
  }, [dispatch, globalId, location, id]);

  const handleSubmit = (values: typeof initialValues) => {
    const payload = {
      id: globalId || id,
      property_size: values.acreage,
      guest_number: values.guests,
      total_rooms: values.bedroom,
      // beds: values.beds,
      total_bathrooms: values.bathroom,
      // kitchen: values.kitchen,
    };

    dispatch(updateProperties({ property: payload }))
      .unwrap()
      .then((response) => {
        if (response?.success) {
          successHandler(response?.message || "Updated successfully");
          navigate(`/add-listing-4?id=${response.property.id || globalId}`);
        } else {
          console.log("Operation failed: ", response);
        }
      })
      .catch((error) => {
        console.log("Error in operation: ", error);
      });
  };

  return (
    <CommonLayout
      index="03"
      backtHref="/add-listing-2"
      nextHref="/add-listing-4"
    >
      <>
        <h2 className="text-2xl font-semibold">Size of your location</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* FORM */}
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={SizeValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className="space-y-8">
              {/* Acreage Select */}
              <FormItem label="Average (m²)">
                <Field as="select" name="acreage" className="input-select">
                  <option value="" disabled>
                    Select Average
                  </option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="300">300</option>
                  <option value="400">400</option>
                  <option value="500">500</option>
                </Field>
                {errors.acreage && touched.acreage && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors.acreage}
                  </div>
                )}
              </FormItem>

              {/* Guests Input */}
              <FormInputNumber name="guests" label="Guests" min={1} max={100} />

              {/* Bedroom Input */}
              <FormInputNumber
                name="bedroom"
                label="Bedroom"
                min={1}
                max={20}
              />

              {/* Beds Input */}
              {/* <FormInputNumber name="beds" label="Beds" min={1} max={50} /> */}

              {/* Bathroom Input */}
              <FormInputNumber
                name="bathroom"
                label="Bathroom"
                min={1}
                max={20}
              />

              {/* Kitchen Input */}
              {/* <FormInputNumber
                name="kitchen"
                label="Kitchen"
                min={1}
                max={10}
              /> */}

              {/* Submit Button */}
              <div className="flex justify-end space-x-5">
                <ButtonSecondary href={`/add-listing-2?id=${globalId || id}`}>
                  Go back
                </ButtonSecondary>
                <ButtonPrimary type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Loading..." : "Continue"}
                </ButtonPrimary>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </CommonLayout>
  );
};

export default PageAddListing3;
