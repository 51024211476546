import React from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";

export interface AlertProps {
  containerClassName?: string;
  type?: "default" | "warning" | "info" | "success" | "error";
  children: React.ReactNode;
}

export const Alert: React.FC<AlertProps> = ({
  children = "Alert Text",
  containerClassName = "",
  type = "default",
}) => {
  let classes = containerClassName;
  switch (type) {
    case "default":
      classes += " text-black bg-gray-200";
      break;
    case "info":
      classes += " bg-blue-100 text-blue-800";
      break;
    case "success":
      classes += " bg-green-100 text-green-800";
      break;
    case "error":
      classes += " bg-red-100 text-red-800";
      break;
    case "warning":
      classes += " bg-yellow-100 text-yellow-800";
      break;
    default:
      break;
  }

  return (
    <div
      className={`relative flex items-center text-paragraph-base px-6 pt-4 pb-3 rounded-lg ${classes}`}
      role="alert"
    >
      <i className="pe-7s-info text-2xl mr-2"></i>
      {children}
      <ButtonClose className="absolute top-2 right-6" />
    </div>
  );
};

export default Alert;
