import React from "react";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import './CustomDatePicker.scss'

interface CustomDatePickerProps {
  value: Dayjs | null;
  onChange: (date: Dayjs | null, dateString: string) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: string | undefined | false;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  value,
  onChange,
  placeholder = "Select Date",
  disabled = false,
  error,
}) => {
  const handleDateChange = (date: Dayjs | null, dateString: string | string[]) => {
    const formattedDateString = Array.isArray(dateString) ? dateString[0] : dateString;
    
    if (date && dayjs.isDayjs(date) && date.isValid()) {
      onChange(date, formattedDateString);
    } else {
      onChange(null, ""); // Handle invalid or null date
    }
  };

  return (
    <div>
      <DatePicker
        value={value}
        onChange={handleDateChange}
        disabled={disabled}
        placeholder={placeholder}
        className="custom-datepicker block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 text-neutral-700 dark:text-neutral-300 "

      />
      {error && <div className="text-red-600 text-sm mt-1">{error}</div>}
    </div>
  );
};

export default CustomDatePicker;
