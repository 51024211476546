import React, { useState, useEffect, useRef } from "react";
import { MessageBox } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import { sendMessage } from "../../redux/reducers/UserSlice/UserSlice";
import { getMessage } from "../../redux/reducers/UserSlice/UserSlice";
import { useDispatch } from "react-redux";
import { RootState, AppDispatch } from "redux/store";
import { useSelector } from "react-redux";
import ChatList from "./Chat";
import { getChatLists } from "../../redux/reducers/ChatReducer/ChatSlice";
import {
  Message,
  SendMessagePayload,
} from "../../redux/reducers/ChatReducer/ChatSliceTypes";


const Messages = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectChat, setSelectedChat] = useState<any>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [message, setMessage] = useState<string>("");
  const [isMessageSend, setIsMessageSend] = useState<boolean>(false);
  const messageContainerRef = useRef<HTMLDivElement | null>(null);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const chatLists = useSelector((state: RootState) => state.chat.chatLists);
  const extractedChat: any = chatLists.chats?.find(
    (item) => item?.id === selectChat?.id
  );

  const handleSendMessage = async () => {
    if (selectChat?.id) {
      const messageData: SendMessagePayload = {
        body: message,
        chat_id: selectChat.id,
      };
      const action = await dispatch(sendMessage(messageData));
      if (action?.payload?.success) {
        setMessages([...messages, action?.payload.message]);
        setMessage("");

        setTimeout(() => {
          setIsMessageSend(false);
        }, 400);
        setIsMessageSend(true);
      }
    }
  };

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const fetchChatLists = async () => {
    try {
      await dispatch(getChatLists());
    } catch (error) {
      console.log("Error in fetching host chat");
    }
  };

  const getAllMessages = async (chatId: number) => {
    const action = await dispatch(getMessage(chatId));
    if (action?.payload?.success) {
      setMessages(action?.payload?.message);
    }
  };

  useEffect(() => {
    fetchChatLists();
  }, [isMessageSend]);

  const getName = (name: string) => {
    const modifiedName = name[0]?.toUpperCase() + name?.slice(1);
    return modifiedName;
  };

  return (
    <div>
     <div className="flex h-screen bg-white dark:bg-neutral-900">
  {/* Sidebar */}
  <div className="w-1/4 border-r bg-neutral-100 dark:bg-neutral-800 dark:border-neutral-700">
    <div className="px-4 py-6">
      <h2 className="text-xl font-semibold text-neutral-900 dark:text-neutral-100">Messages</h2>

      {/* Message Item */}
      <div className="mt-6">
        <ChatList
          currentUser={currentUser}
          chats={chatLists?.chats}
          setSelectedChat={setSelectedChat}
          getAllMessages={getAllMessages}
        />
      </div>
    </div>
  </div>

  {/* Main Chat Section */}
  {selectChat ? (
    <React.Fragment>
      <div className="flex-1 flex flex-col bg-neutral-100 dark:bg-neutral-800">
        <div className="px-6 py-4 flex justify-between items-center border-b border-neutral-300 dark:border-neutral-700">
          {/* Header */}
          <div className="flex items-center">
            <img
              src={selectChat?.show?.image}
              alt="Profile"
              className="w-10 h-10 rounded-full"
            />
            <div className="ml-4">
              <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-100">
                {getName(selectChat?.show?.name)}
              </h3>
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                Translation on
              </span>
            </div>
          </div>
        </div>

        {/* Messages */}
        <div
          ref={messageContainerRef}
          className="flex-1 overflow-y-auto px-6 py-6 space-y-4 text-center"
        >
          <div>
            <div className="text-sm text-neutral-500 dark:text-neutral-400">Saturday</div>
            <div className="text-sm text-neutral-700 dark:text-neutral-300 mt-1">
              <p>
                We may analyze messages for safety, support, product enhancement
                or other purposes.{" "}
                <a href="#" className="text-primary-500 dark:text-primary-400">
                  Learn more
                </a>
              </p>
              <p className="mt-2">
                Your enquiry for 1 guest on 31 Oct – 5 Nov has been sent.{" "}
                <a href="#" className="text-primary-500 dark:text-primary-400">
                  Show listing
                </a>
              </p>
              <p className="mt-2">
                Annie has invited you to book for 1 guest on 31 Oct – 5 Nov.{" "}
                <a href="#" className="text-primary-500 dark:text-primary-400">
                  Complete booking
                </a>
              </p>
            </div>
          </div>
   
          {/* Messages */}
          <div className="flex-1 overflow-y-auto px-6 py-6 space-y-4">
            <div className="chat-container">
              {messages?.map((msg: any) => (
                <div
                  key={msg.id}
                  className={`flex ${
                    msg.sender.id === currentUser?.id ? "justify-end" : "justify-start"
                  } mb-4`}
                >
                  <div className="w-1/2 text-black text-left">
                    <MessageBox
                      position={msg.sender.id === currentUser?.id ? "right" : "left"}
                      type="text"
                      title={""}
                      text={msg.body}
                      date={new Date(msg.created_at)}
                      titleColor="#000000"
                      replyButton={true}
                      id={msg.id}
                      focus={false}
                      forwarded={false}
                      removeButton={false}
                      status="sent"
                      notch={true}
                      retracted={false}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Input */}
        <div className="px-6 py-4 border-t border-neutral-300 dark:border-neutral-700">
          <div className="flex items-center">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type a message"
              className="w-full p-3 bg-neutral-200 dark:bg-neutral-700 border border-neutral-400 dark:border-neutral-600 rounded-lg text-neutral-900 dark:text-neutral-100 focus:outline-none focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400"
            />
            <button
              onClick={handleSendMessage}
              className="ml-4 bg-primary-500 text-white px-4 py-2 rounded-lg hover:bg-primary-600 dark:hover:bg-primary-400 focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 dark:focus:ring-primary-400"
            >
              Send
            </button>
          </div>
        </div>
      </div>

      {/* Reservation Section */}
      <div className="w-1/4 border-l border-neutral-300 dark:border-neutral-700 bg-neutral-100 dark:bg-neutral-800 p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-100">
            Reservation
          </h3>
        </div>
        
        <div>
          {extractedChat ? (
            <div className="mt-8 p-4 border border-neutral-300 dark:border-neutral-700 rounded-lg bg-neutral-100 dark:bg-neutral-800">
              <div>
                <img
                  src={extractedChat.property.placeImages[0]}
                  alt={selectChat.property.title}
                  className="w-full h-auto rounded-lg mb-4"
                />
                <h4 className="text-lg font-semibold text-neutral-900 dark:text-neutral-100">
                  {selectChat.property.title}
                </h4>
                <p className="text-sm text-neutral-500 dark:text-neutral-400 mt-2">
                  City: {selectChat.property.city}
                </p>
                <p className="text-sm text-neutral-500 dark:text-neutral-400 mt-2">
                  Address: {selectChat.property.address}
                </p>
              </div>
            </div>
          ) : (
            <span className="text-neutral-900 dark:text-neutral-100">No Property found</span>
          )}
        </div>
        
      </div>
    </React.Fragment>
  ) : (
    <div className="w-screen h-screen flex items-center justify-center">
      <h3 className="text-neutral-900 dark:text-neutral-100">
        NO MESSAGE YET. START THE CONVERSATION!
      </h3>
    </div>
  )}
</div>
    </div>
  );

};

export default Messages;
