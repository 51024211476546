import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import InputFile from "shared/InputFile/InputFile";
import {
  getCurrentUser,
  updateUserWithImageAPI,
} from "../../redux/reducers/UserSlice/UserSlice";
import { AppDispatch, RootState } from "../../redux/store";
import successHandler from "../../utils/helpers/SuccessHandler";
import { toast } from "react-toastify";

const Educations = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser } = useSelector((state: RootState) => state.user);
  const tenantType = useSelector((state: RootState) => state.user.tenantType);

  const [universityFileName, setUniversityFileName] = useState<string>("");
  const [workFileName, setWorkFileName] = useState<string>("");

  const formik = useFormik({
    initialValues: {
      university_letter: null,
      work_letter: null,
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      const formData = new FormData();
      if (values.university_letter) {
        formData.append("user[university_loa]", values.university_letter);
      }
      if (values.work_letter) {
        formData.append("user[work_loa]", values.work_letter);
      }
      dispatch(updateUserWithImageAPI(formData))
        .unwrap()
        .then(() => {
          successHandler("Documents uploaded successfully");
          dispatch(getCurrentUser());
        })
        .catch((error) => {
          console.error("Error uploading documents:::: ", error?.response?.data?.message);
          toast.error(error?.response?.data?.message || "Error uploading documents")
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });
  useEffect(() => {
    if (currentUser?.id) {
      setUniversityFileName(currentUser?.university_loa_file_name || "");
      setWorkFileName(currentUser?.work_loa_file_name || "");
    }
  }, [currentUser]);

  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex-shrink-0 flex items-start">
        <div className="relative rounded-full overflow-hidden flex">
          <Avatar
            sizeClass="w-32 h-32"
            containerClassName="hidden md:block invisible"
          />
        </div>
      </div>
      <div className="flex-grow mt-10 md:mt-0 md:ml-8 space-y-6">
        <h2 className="text-3xl font-semibold mt-6 mb-4">Documentation</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mt-6 mb-6"></div>

        {tenantType === "professional" ? (
          <div>
            <h2 className="text-lg font-semibold mb-2">
              Work/Volunteer Acceptance Letter
            </h2>
            <div className="flex justify-between items-center">
              <div className="flex gap-1 items-center">
                <InputFile
                  name="work_letter"
                  fileName={workFileName}
                  onChange={(files) => {
                    formik.setFieldValue(
                      "work_letter",
                      files ? files[0] : null
                    );
                    setWorkFileName(files ? files[0].name : "");
                  }}
                  disabled={!currentUser?.email_verified}
                />
                {workFileName && (
                  <div className="ml-4 text-gray-700">{workFileName}</div>
                )}
              </div>
              {currentUser?.work_loa && (
                <div>
                  <a
                    href={currentUser.work_loa}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    View
                  </a>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <h2 className="text-lg font-semibold mb-2">
              University Letter of Acceptance
            </h2>
            <p className="text-sm text-gray-600 mb-4">
              It is not mandatory to upload your exchange program letter of
              acceptance right now, but some hosts might ask for it before
              accepting your booking request.
            </p>

            <div className="flex justify-between items-center">
              <div className="flex gap-1 items-center">
                <InputFile
                  name="university_letter"
                  fileName={universityFileName}
                  onChange={(files) => {
                    formik.setFieldValue(
                      "university_letter",
                      files ? files[0] : null
                    );
                    setUniversityFileName(files ? files[0].name : "");
                  }}
                  disabled={!currentUser?.email_verified}
                />
                {universityFileName && (
                  <div className="ml-4 text-gray-700">{universityFileName}</div>
                )}
              </div>
              {currentUser?.university_loa && (
                <div>
                  <a
                    href={currentUser.university_loa}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    View
                  </a>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="flex justify-end">
          <ButtonPrimary
            type="submit"
            className="mt-5"
            loading={formik.isSubmitting}
            disabled={!currentUser?.email_verified}
            onClick={formik.handleSubmit}
          >
            Save
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default Educations;
