import { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { RootState, AppDispatch } from "redux/store";
import {
  getCurrentUser,
  setTenantType,
  updateUserAPI,
  updateUserEmailAsVerified,
  updateUserWithImageAPI,
} from "../../redux/reducers/UserSlice/UserSlice";
import successHandler from "../../utils/helpers/SuccessHandler";
import { personalDetailsSchema } from "../../utils/formSchema";
import FormField from "../../shared/FormField/FormField";
import __countryListing from "../../data/jsons/__countryListing.json";
import PhoneNumberInput from "shared/PhoneNumberInput/PhoneNumberInput";
import { User } from "../../redux/reducers/UserSlice/UserSliceType";
import { toast } from "react-toastify";
const Details = ({ className = "" }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser, loading } = useSelector(
    (state: RootState) => state.user
  );

  const formik = useFormik<User>({
    initialValues: {
      first_name: currentUser?.first_name || "",
      last_name: currentUser?.last_name || "",
      email: currentUser?.email || "",
      date_of_birth: currentUser?.date_of_birth || "",
      phone: currentUser?.phone || "",
      gender: currentUser?.gender || "",
      country_code: currentUser?.country_code || "",
      about_me: currentUser?.about_me || "",
      live_in: currentUser?.live_in || "",
      speak_language: currentUser?.speak_language || "",
      tenant_type: currentUser?.tenant_type || "student",
      nationality: currentUser?.nationality || "",
      home_university: currentUser?.home_university || "",
      abroad_university: currentUser?.abroad_university || "",
      education_major: currentUser?.education_major || "",
      company_name: currentUser?.company_name || "",
    },
    validationSchema: personalDetailsSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      dispatch(updateUserAPI({ user: values }))
        .unwrap()
        .then((response) => {
          if (response) {
            successHandler("User information updated successfully");
            dispatch(getCurrentUser());
          }
        })
        .catch((error: any) => {
          console.error("Error updating user information: ", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    // If the userData is loaded, update formik values
    if (currentUser) {
      formik.setValues({
        first_name: currentUser.first_name || "",
        last_name: currentUser.last_name || "",
        email: currentUser.email || "",
        date_of_birth: currentUser.date_of_birth || "",
        phone: currentUser.phone || "",
        gender: currentUser.gender || "",
        country_code: currentUser.country_code || "",
        about_me: currentUser.about_me || "",
        live_in: currentUser?.live_in || "",
        speak_language: currentUser.speak_language || "",
        tenant_type: currentUser?.tenant_type || "student",
        nationality: currentUser?.nationality || "",
        home_university: currentUser?.home_university || "",
        abroad_university: currentUser?.abroad_university || "",
        education_major: currentUser?.education_major || "",
        company_name: currentUser?.company_name || "",
      });
    }
  }, [currentUser]);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("user[profile_image]", file);
      try {
        dispatch(updateUserWithImageAPI(formData))
          .unwrap()
          .then((response) => {
            if (response) {
              dispatch(getCurrentUser());
              successHandler("User profile image updated successfully");
            }
          })
          .catch((error: any) => {
            console.error("Error updating user profile image: ", error);
          })
          .finally(() => {
            // setSubmitting(false);
          });
      } catch (error) {
        console.error("Error updating user profile image: ", error);
      }
      // Add any logic to preview the image or upload it to the server here
    }
  };
  return (
    <>
      {/* <Helmet>
        <title>Account || Booking React Template</title>
      </Helmet> */}
      {/* <h2 className="text-3xl font-semibold mt-6 mb-4">Your Details</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mt-6 mb-6"></div> */}
      <div className="flex flex-col md:flex-row mt-6">
        <div className="flex-shrink-0 flex items-start">
          <div className="relative rounded-full overflow-hidden flex">
            <Avatar
              sizeClass="w-32 h-32"
              hasChecked={true}
              imgUrl={currentUser?.image}
              userName={currentUser?.first_name}
            />
            <div>
              <div className="absolute inset-0 bg-black bg-opacity-30 flex flex-col items-center justify-center text-neutral-50 cursor-pointer opacity-80">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                    stroke="currentColor"
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="mt-1 text-xs">Change Image</span>
              </div>
            </div>
            <input
              type="file"
              onChange={(e) => handleFileChange(e)}
              disabled={!currentUser?.email_verified}
              className="absolute inset-0 opacity-0 cursor-pointer"
            />
          </div>
          {currentUser?.email_verified && (
            <div className="-ml-9 mt-1 z-10">
              <svg
                width="20"
                height="20"
                viewBox="0 0 122.88 116.87"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
              >
                <polygon
                  fill="#10a64a"
                  points="61.37 8.24 80.43 0 90.88 17.79 111.15 22.32 109.15 42.85 122.88 58.43 109.2 73.87 111.15 94.55 91 99 80.43 116.87 61.51 108.62 42.45 116.87 32 99.08 11.73 94.55 13.73 74.01 0 58.43 13.68 42.99 11.73 22.32 31.88 17.87 42.45 0 61.37 8.24 61.37 8.24"
                />
                <path
                  fill="#fff"
                  d="M37.92,65c-6.07-6.53,3.25-16.26,10-10.1,2.38,2.17,5.84,5.34,8.24,7.49L74.66,39.66C81.1,33,91.27,42.78,84.91,49.48L61.67,77.2a7.13,7.13,0,0,1-9.9.44C47.83,73.89,42.05,68.5,37.92,65Z"
                />
              </svg>
            </div>
          )}
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="flex-grow mt-10 md:mt-0 md:ml-8 space-y-6"
        >
          {/* Existing fields */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <FormField
              label=" * First Name"
              name="first_name"
              placeholder="John"
              type="text"
              disabled={!currentUser?.email_verified}
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.first_name && formik.errors.first_name}
            />

            <FormField
              label=" * Last Name"
              name="last_name"
              type="text"
              placeholder="Doe"
              disabled={!currentUser?.email_verified}
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.last_name && formik.errors.last_name}
            />

            <FormField
              label="Gender"
              name="gender"
              placeholder="Select"
              type="select"
              disabled={!currentUser?.email_verified}
              value={formik.values.gender?.toLowerCase()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              options={[
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
                { value: "other", label: "Other" },
              ]}
              error={formik.touched.gender && formik.errors.gender}
            />

            <FormField
              label=" * Email"
              name="email"
              placeholder="john.doe@example.com"
              type="email"
              disabled={true}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && formik.errors.email}
            />

            <div>
              <PhoneNumberInput
                label="* Phone/whatsapp:"
                name="phone"
                placeholder="Enter a phone number"
                disabled={!currentUser?.email_verified}
                value={{
                  phone: formik.values.phone,
                  country_code: formik.values.country_code,
                }}
                onChange={(value: { phone: any; country_code: any }) => {
                  formik.setFieldValue("phone", value.phone);
                  formik.setFieldValue("country_code", value.country_code);
                }}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className="text-red-500">{formik.errors.phone}</div>
              )}
            </div>

            <FormField
              label=" * DOB"
              name="date_of_birth"
              placeholder="MM/DD/YYYY"
              type="date"
              disabled={!currentUser?.email_verified}
              value={formik.values.date_of_birth}
              onChange={(value) => formik.setFieldValue("date_of_birth", value)} // Set the value manually for dates
              onBlur={formik.handleBlur}
              error={
                formik.touched.date_of_birth && formik.errors.date_of_birth
              }
            />

            <FormField
              label=" * Nationality"
              name="nationality"
              placeholder="Select your country"
              type="select"
              disabled={!currentUser?.email_verified}
              value={formik?.values?.nationality}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.nationality && formik.errors.nationality}
              options={__countryListing.map((country) => ({
                value: country.name,
                label: country.name,
              }))}
            />

            <FormField
              label="Speak Language"
              name="speak_language"
              placeholder="Language Name"
              type="text"
              disabled={!currentUser?.email_verified}
              value={formik.values.speak_language}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.speak_language && formik.errors.speak_language
              }
            />
            <FormField
              label=" * Tenant Type"
              name="tenant_type"
              type="select"
              disabled={!currentUser?.email_verified}
              value={formik.values.tenant_type}
              onChange={(e: any) => {
                formik.handleChange(e);
                dispatch(setTenantType(e?.target.value));
              }}
              onBlur={formik.handleBlur}
              options={[
                { value: "student", label: "Student" },
                { value: "professional", label: "Professional" },
              ]}
              error={formik.touched.tenant_type && formik.errors.tenant_type}
            />

            {formik.values.tenant_type === "student" ? (
              <>
                <FormField
                  label=" * Home university"
                  name="home_university"
                  placeholder="Home university"
                  type="text"
                  disabled={!currentUser?.email_verified}
                  value={formik.values.home_university}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.home_university &&
                    formik.errors.home_university
                  }
                />

                <FormField
                  label=" * Abroad university"
                  name="abroad_university"
                  placeholder="Abroad university"
                  type="text"
                  disabled={!currentUser?.email_verified}
                  value={formik.values.abroad_university}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.abroad_university &&
                    formik.errors.abroad_university
                  }
                />

                <FormField
                  label=" * Education Major"
                  name="education_major"
                  placeholder="Education major"
                  type="text"
                  disabled={!currentUser?.email_verified}
                  value={formik.values.education_major}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.education_major &&
                    formik.errors.education_major
                  }
                />
              </>
            ) : (
              <>
                <FormField
                  label=" * Company where you will work/internship abroad"
                  name="company_name"
                  placeholder="Company name"
                  type="text"
                  disabled={!currentUser?.email_verified}
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.company_name && formik.errors.company_name
                  }
                />

                <FormField
                  label=" * Country where you currently live"
                  name="live_in"
                  placeholder="Select your country"
                  type="select"
                  disabled={!currentUser?.email_verified}
                  value={formik.values.live_in}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.live_in && formik.errors.live_in}
                  options={__countryListing.map((country) => ({
                    value: country.name,
                    label: country.name,
                  }))}
                />
              </>
            )}
          </div>

          <FormField
            label=" * About Me"
            name="about_me"
            placeholder="Tell us about yourself"
            type="textarea"
            disabled={!currentUser?.email_verified}
            value={formik.values.about_me}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.about_me && formik.errors.about_me}
          />
          <div className="flex justify-end">
            <ButtonPrimary
              type="submit"
              disabled={!currentUser?.email_verified}
              className="mt-5"
              loading={formik.isSubmitting}
            >
              Save
            </ButtonPrimary>
          </div>
        </form>
      </div>
    </>
  );
};

export default Details;
