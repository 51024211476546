import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteInvoice,
  getBookingsDetails,
  postChatBooking,

} from "../../redux/reducers/Properties/PropertiesSlice";
import { AppDispatch, RootState } from "redux/store";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useLocation } from "react-router-dom";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { BookingRequestDetails } from "redux/reducers/Properties/PropertiesSliceTypes";
import Invoice from "../Booking/Invoice";
import InvoiceUser from "../Booking/InvoiceUser";
import successHandler from "utils/helpers/SuccessHandler";
import { useSelector } from "react-redux";
import { updateBookingsDetails } from "../../redux/reducers/Payments/PaymentReducer";

export interface BookingRequest {
  className?: string;
  // data?: StayDataType;
  // size?: "default" | "small";
}

const BookingRequest: FC<BookingRequest> = ({
  className = "",
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [bookingRequestDetails, setBookingRequestDetails] = useState<BookingRequestDetails | null>(null);
  const [replyText, setReplyText] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [chatBookingMap, setChatBookingMap] = useState<any>([]);
  const modifiedBookingMap = bookingRequestDetails?.last_five_messages ? [...bookingRequestDetails?.last_five_messages]?.reverse() : []
  const [showInvoice, setShowInvoice] = useState(false);
  const [showUserInvoice, setShowUserInvoice] = useState(false)
  const { currentUser } = useSelector((state: RootState) => state.user);
  const openInvoice = () => setShowInvoice(true);
  const closeInvoice = () => setShowInvoice(false);
  const [isDeleting, setIsDeleting] = useState(false);


  useEffect(() => {
    fetchAllManageBooking();
  }, [dispatch]);

  const checkInDate = bookingRequestDetails?.check_in_date
    ? new Date(bookingRequestDetails.check_in_date).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })
    : '';

  const checkOutDate = bookingRequestDetails?.check_out_date
    ? new Date(bookingRequestDetails.check_out_date).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })
    : '';

  const handleFileChange = (e: { target: { files: FileList | null }; }) => {
    if (e.target.files) {

      const file = e.target.files[0];
      if (file && file.type.startsWith('image/')) {
        setSelectedFile(file);
      }
    }
  };

  const fetchAllManageBooking = async () => {
    const action = await dispatch(getBookingsDetails(id));
    if (action?.payload?.success) {
      setBookingRequestDetails(action.payload.booking);
    }
  };


  const handleSubmit = async () => {
    if (!replyText.trim() && !selectedFile) return;
    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append("message[body]", replyText);
      if (selectedFile) {
        formData.append("file", selectedFile);
      }

      const data = {
        chatId: bookingRequestDetails?.chat?.id,
        formData,
      };
      await dispatch(postChatBooking(data)).unwrap();
      setReplyText("");
      setSelectedFile(null);
      fetchAllManageBooking();
    } catch (error) {
      console.error("Failed to send reply:", error);
    } finally {
      setIsSubmitting(false);
    }
  };



  const handleCancelBooking = async () => {
    if (id == null) {
      console.error("ID is null or undefined");
      return;
    }

    try {
      const action = await dispatch(updateBookingsDetails({ id, status: "canceled" }));
      if (updateBookingsDetails.fulfilled.match(action)) {
        successHandler("Booking canceled successfully:", action.payload);
        await fetchAllManageBooking(); // Refresh booking data after cancellation
      } else {
        console.error("Failed to cancel booking:", action.payload);
      }
    } catch (error) {
      console.error("Error canceling booking:", error);
    }
  };

  const handleDeleteInvoice = async () => {
    if (id == null) {
      console.error("ID is null or undefined");
      return;
    }

    // Set the deleting state to true when the deletion begins
    setIsDeleting(true);

    const postData = {
      bookingId: id,
      id: bookingRequestDetails?.invoice?.id,
      _destroy: true,
      status: "canceled", // Set status here as needed
    };

    try {
      const action = await dispatch(deleteInvoice(postData));
      if (deleteInvoice.fulfilled.match(action)) {
        successHandler("Invoice deleted successfully:", action.payload);
        await fetchAllManageBooking(); // Refresh booking data after deletion
      } else {
        console.error("Failed to delete invoice:", action.payload);
      }
    } catch (error) {
      console.error("Error deleting invoice:", error);
    } finally {
      // Reset deleting state once the deletion process finishes
      setIsDeleting(false);
    }
  };

  const renderSliderGallery = () => (
    <div className="w-full sm:w-[200px] md:w-[220px] lg:w-[360px]">
      <GallerySlider
        uniqueID={`StayCard_${id}`}
        ratioClass="aspect-w-4 aspect-h-3"
        galleryImgs={bookingRequestDetails?.property?.place_images || []}
        href={"#"}
      />
    </div>
  );


  const pathArray = [
    { path: 1, type: 'Receive a message', status: "message_received" },
    { path: 2, type: 'Send invitation to book', status: "booking_request" },
    { path: 3, type: 'Send invitation to book', status: "invitation_sent" },
    { path: 4, type: 'Wait for payment of first month', status: "invoice_sent" },
    { path: 5, type: 'Booking confirmed', status: "confirmed" },
  ];


  return (
    <div className="max-w-7xl mx-auto p-4 lg:container ">
      <h1 className="text-xl font-bold text-gray-700 dark:text-gray-200 mb-6">MESSAGE FROM YOPMAIL</h1>
      <div className="mx-auto grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="md:col-span-2 mb-8">
          <div className="lg:col-span-2">
            <div className="relative flex flex-col md:flex-row justify-between items-center border border-gray-300 dark:bg-black p-8 text-xl dark:border-gray-700">
              <div className="flex justify-between w-full">
                {pathArray.map((step) => (
                  <div key={step.path} className="flex flex-col items-center md:w-1/5 w-full mb-4 md:mb-0 relative">
                    <div
                      className={`w-8 h-8 rounded-full flex items-center justify-center mb-2 ${step.status === bookingRequestDetails?.status
                        ? 'bg-blue-500 text-white' // Active step
                        : step.path < pathArray.findIndex(s => s.status === bookingRequestDetails?.status) + 1
                          ? 'bg-red-500 text-white' // Completed steps
                          : 'bg-gray-300 dark:bg-gray-700 text-white' // Inactive steps
                        }`}
                    >
                      {step.path}
                    </div>
                    <span className="text-xs text-center dark:text-gray-300">{step.type}</span>
                  </div>
                ))}
              </div>

            </div>
            <div className="bg-white dark:bg-black shadow p-6 border border-gray-300 dark:border-gray-700 mt-5">
              <h2 className="font-semibold mb-4 text-gray-700 dark:text-gray-200">
                Booking Request {bookingRequestDetails?.number} for {bookingRequestDetails?.property?.title}
              </h2>
              <div className="space-y-2 mb-6">
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  Period: {checkInDate} to {checkOutDate}
                </p>
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  Invoice No: {bookingRequestDetails?.invoice?.id || null}
                </p>
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  Pay Amount: USD {bookingRequestDetails?.total} Guests: {bookingRequestDetails?.number_of_guests}
                </p>
                <p className="text-sm text-orange-500 font-bold">
                  Balance: USD {bookingRequestDetails?.payment_state} to be paid before {bookingRequestDetails?.dueDate}
                </p>
              </div>
              <div className="bg-gray-200 dark:bg-gray-700 p-4 rounded-lg">
                <div className="flex gap-2">
                  {bookingRequestDetails?.status === "confirmed" ? (
                    <ButtonPrimary
                      className="bg-green-500 text-white px-4 py-2 rounded-lg"
                      disabled
                    >
                      Booking Confirmed
                    </ButtonPrimary>
                  ) : currentUser?.is_host ? (
                    <>
                      {/* Host Buttons */}
                      {bookingRequestDetails?.status !== "canceled" && (
                        bookingRequestDetails?.status === "invoice_sent" ? (
                          <>
                            <ButtonPrimary
                              className="bg-gray-500 text-white px-4 py-2 rounded-lg"
                              disabled
                            >
                              Invoice Issued
                            </ButtonPrimary>

                            {bookingRequestDetails?.invoice?.id != null && (
                              <ButtonPrimary
                                onClick={handleDeleteInvoice}
                                disabled={isDeleting}
                                className={`bg-red-500 text-white px-4 py-2 rounded-lg ${isDeleting
                                  ? 'opacity-50 cursor-not-allowed'
                                  : 'hover:bg-red-600'
                                  }`}
                              >
                                {isDeleting ? 'Deleting...' : 'Delete Invoice'}
                              </ButtonPrimary>
                            )}
                          </>
                        ) : (
                          <ButtonPrimary
                            className="bg-orange-500 text-white px-4 py-2 rounded-lg"
                            onClick={openInvoice}
                          >
                            Issue Invoice
                          </ButtonPrimary>
                        )
                      )}
                      <ButtonPrimary
                        className="bg-orange-500 text-white px-4 py-2 rounded-lg"
                        onClick={handleCancelBooking}
                        disabled={bookingRequestDetails?.status === "canceled"}
                      >
                        Reject Booking Request
                      </ButtonPrimary>
                    </>
                  ) : (
                    <>
                      {/* Non-Host Buttons */}
                      {bookingRequestDetails?.status === "canceled" ? (
                        <button
                          className="bg-gray-300 dark:bg-gray-600 p-5 rounded-lg text-gray-700 dark:text-gray-300"
                          disabled
                        >
                          Request Pending
                        </button>
                      ) : bookingRequestDetails?.status === "booking_request" ? (
                        <button
                          className="bg-gray-300 dark:bg-gray-600 p-5 rounded-lg text-gray-700 dark:text-gray-300"
                          disabled
                        >
                          Request Pending
                        </button>
                      ) : bookingRequestDetails?.status === "invoice_sent" ? (
                        <button
                          className="bg-green-500 text-white px-4 py-2 rounded-lg"
                          onClick={() => setShowUserInvoice(true)}
                        >
                          Invoice Created - Check & Pay
                        </button>
                      ) : null}

                      <ButtonPrimary
                        className="bg-orange-500 text-white px-4 py-2 rounded-lg"
                        onClick={handleCancelBooking}
                        disabled={bookingRequestDetails?.status === "canceled"}
                      >
                        Cancel Booking Request
                      </ButtonPrimary>

                      <ButtonPrimary
                        href={`/listing-stay-detail?id=${bookingRequestDetails?.property?.id}`}
                        className="bg-orange-500 text-white px-4 py-2 rounded-lg"
                      >
                        Visit Property
                      </ButtonPrimary>
                    </>
                  )}
                </div>


                {/* Conditionally Render Invoices */}
                {showUserInvoice && <InvoiceUser bookingRequestDetails={bookingRequestDetails} />}
                {showInvoice && <Invoice bookingRequestDetails={bookingRequestDetails} closeInvoice={closeInvoice} />}
              </div>

              <div className="mt-6 p-4 bg-gray-100 dark:bg-black">
                <span className="text-gray-500 dark:text-gray-400">Reply</span>
                <textarea
                  className="w-full border border-gray-300 dark:border-gray-700 rounded-lg p-3 min-h-[80px] focus:outline-none focus:border-gray-400 dark:focus:border-gray-600 mt-4 bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-300"
                  placeholder="Write your reply..."
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                  disabled={isSubmitting}
                />
                <div className="flex justify-end items-center mt-3 gap-5">
                  <label
                    htmlFor="cover-image-upload"
                    className="text-blue-500 dark:text-blue-400 text-sm cursor-pointer flex items-center gap-1"
                  >
                    📎 <span>Upload file</span>
                    <input
                      id="cover-image-upload"
                      name="cover-image-upload"
                      type="file"
                      accept="image/*"
                      className="sr-only"
                      onChange={handleFileChange}
                      disabled={isSubmitting}
                    />
                  </label>
                  {selectedFile && (
                    <span className="text-sm text-gray-600 dark:text-gray-400">
                      {selectedFile?.name}
                    </span>
                  )}
                  <button
                    onClick={handleSubmit}
                    disabled={isSubmitting || (!replyText.trim() && !selectedFile)}
                    className={`bg-orange-500 text-white px-4 py-2 rounded-lg ${(isSubmitting || (!replyText.trim() && !selectedFile))
                      ? 'opacity-50 cursor-not-allowed'
                      : 'hover:bg-orange-600'
                      }`}
                  >
                    {isSubmitting ? 'Sending...' : 'Send Reply'}
                  </button>
                </div>
              </div>
              <div className="mt-6 border border-gray-300 p-4 space-y-4 bg-white dark:bg-black dark:border-gray-700">
                {modifiedBookingMap?.map((msg: any, index: number) => (
                  <div key={index}>
                    {msg.sender?.id ? (
                      <div className="flex items-start space-x-4 p-4 bg-gray-100 dark:bg-black rounded-lg">
                        <div className="w-12 h-12 rounded-full bg-purple-200 flex items-center justify-center">
                          {msg.sender.image ? (
                            <img
                              src={msg.sender.image}
                              alt="Sender"
                              className="w-full h-full rounded-full object-cover"
                            />
                          ) : (
                            <span className="text-xl font-semibold text-white">
                              {msg.sender.name ? msg.sender.name.charAt(0).toUpperCase() : "?"}
                            </span>
                          )}
                        </div>

                        <div>
                          <div className="flex items-center space-x-2">
                            <h3 className="text-sm font-semibold text-gray-700 dark:text-gray-200">
                              {msg.sender.name || "User"}
                            </h3>
                          </div>
                          <span className="text-xs text-gray-500 dark:text-gray-400">
                            {msg.body}
                          </span>
                          <p className="text-sm text-gray-600 dark:text-gray-300 mt-2">
                            Sent for property {bookingRequestDetails?.property?.title}, with the link:{" "}
                            <a
                              href={`https://www.staging2.nomadroof.com/properties/${bookingRequestDetails?.property?.title}/`}
                              className="text-blue-500 hover:underline"
                            >
                              {`https://www.staging2.nomadroof.com/properties/${bookingRequestDetails?.property?.title}/`}
                            </a>
                          </p>
                          <p className="text-sm text-gray-600 dark:text-gray-300">
                            Selected dates: {bookingRequestDetails?.check_in_date} to {bookingRequestDetails?.check_out_date}, guests: {bookingRequestDetails?.number_of_guests}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="border-b border-gray-300 dark:border-gray-700 pb-4 text-sm text-gray-600 dark:text-gray-300">
                        <span className="font-semibold text-gray-700 dark:text-gray-200">System Message:</span>
                        <p className="leading-8">{msg.body}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-orange-500 h-96 rounded-lg p-6">
            <div className="flex flex-col items-center">
              <h3 className="font-semibold mb-4 text-white">
                {bookingRequestDetails?.user?.image ? (
                  <img
                    src={bookingRequestDetails.user.image}
                    alt="User"
                    className="w-20 h-20 rounded-full object-cover"
                  />
                ) : (
                  bookingRequestDetails?.user?.first_name
                    ?.charAt(0)
                    .toUpperCase()
                )}
              </h3>
              <h3 className="font-semibold mb-4 text-white">
                {bookingRequestDetails?.user?.first_name}
              </h3>
              <div className="space-y-2 w-full">
                <p className="text-sm mt-4">
                  {bookingRequestDetails?.user?.live_in
                    ? `Nationality: ${bookingRequestDetails?.user?.live_in}`
                    : "Not available"}
                </p>
                <p className="text-sm mt-4">
                  {bookingRequestDetails?.user?.speak_language
                    ? `Language: ${bookingRequestDetails?.user?.speak_language}`
                    : "Not available"}
                </p>
                {/* <p className="text-sm">👤 age</p> */}
                <p className="text-sm">
                  {bookingRequestDetails?.user?.email_verified
                    ? "✓ Email verified"
                    : "✘ Email not verified"}
                </p>
                {/* <div className="overflow-y-auto max-h-32">
                                    <p className="text-sm mt-4">
                                        {bookingRequestDetails?.user?.about_me
                                            ? `About me: ${bookingRequestDetails?.user?.about_me}`
                                            : "No about me information available"}
                                    </p>
                   </div> */}
              </div>
            </div>
          </div>
          <div className="w-full mt-5">
            <div className="dark:bg-black border border-gray-200 p-6 shadow-sm rounded-md w-full flex items-center justify-center dark:border-gray-700">
              <div className="space-y-4">
                <div className="space-y-1">
                  <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
                    {bookingRequestDetails?.property?.title}
                  </h2>
                  <p className="text-gray-600 dark:text-gray-400">
                    {bookingRequestDetails?.property?.address}
                  </p>
                </div>
                <div
                  className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
                  data-nc-id=""
                >
                  {renderSliderGallery()}
                  {/* <Link to={data?.href || "#"}>{renderContent()}</Link> */}
                </div>
                <div className="space-y-3">
                  <div className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">
                      Period :
                    </span>
                    <span className="text-gray-800 dark:text-gray-100">
                      {checkInDate} to {checkOutDate}
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">
                      No. of Months:
                    </span>
                    <span className="text-gray-800 dark:text-gray-100">1</span>
                  </div>

                  <div className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">
                      Guests:
                    </span>
                    <span className="text-gray-800 dark:text-gray-100">
                      {" "}
                      {bookingRequestDetails?.number_of_guests}{" "}
                    </span>
                  </div>

                  {/* <div className="flex justify-between">
                                        <span className="text-gray-600 dark:text-gray-400">Price per Month:</span>
                                        <span className="text-gray-800 dark:text-gray-100">{bookingRequestDetails?.property?.price_per_night}</span>
                                    </div> */}
                </div>
                <hr className="border-gray-200 dark:border-gray-700" />
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      First Month's Rent
                    </p>
                    <div>
                      <span className="text-gray-800 dark:text-gray-100">
                        {bookingRequestDetails?.item_total}
                      </span>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">
                      One-Time Service Fee:
                    </span>
                    <span className="text-gray-800 dark:text-gray-100">
                      {bookingRequestDetails?.tax_total}
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">
                      Invoice total:
                    </span>
                    <span className="text-gray-800 dark:text-gray-100">
                      {bookingRequestDetails?.invoice?.total}
                    </span>
                  </div>

                  <div className="flex justify-between pt-4">
                    <span className="text-gray-600 dark:text-gray-400">
                      Total
                    </span>
                    <span className="text-xl font-semibold text-gray-800 dark:text-gray-100">
                      {bookingRequestDetails?.total_amount}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default BookingRequest;
