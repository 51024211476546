import React from "react";
import { ReactNode } from "react";

export interface Heading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
  searchedData?: any
}

const Heading2: React.FC<Heading2Props> = ({
  className = "",
  subHeading,
  searchedData
}) => {
  const propertiesCount = searchedData?.total_count;
  const properties = searchedData?.properties?.length > 0 && searchedData?.properties
  const city  = properties[0]?.city
  const heading = `Stays in ${city ? city : "Tokyo"}`

  return (
    <div className={`mb-12 lg:mb-16 ${className}`}>
      <h2 className="text-4xl font-semibold">{heading}</h2>
      {subHeading ? (
        subHeading
      ) : (
        <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
          {propertiesCount ? propertiesCount : 10} stays
          <span className="mx-2">·</span>
          Aug 12 - 18
          <span className="mx-2">·</span>2 Guests
        </span>
      )}
    </div>
  );
};

export default Heading2;
