import React, { useState } from "react";
import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { signUp } from "../../redux/reducers/AuthSlice/AuthSlice";
import { getCurrentUser } from "../../redux/reducers/UserSlice/UserSlice";
import SocialAuth from "../../components/SocialAuth/SocialAuth";
import successHandler from "../../utils/helpers/SuccessHandler";
import { SignUpSchemaForStudent, SignUpSchemaForHost } from "../../utils/formSchema";
import ButtonThird from "shared/Button/ButtonThird";
import FormField from "../../shared/FormField/FormField";
import PhoneNumberInput from "shared/PhoneNumberInput/PhoneNumberInput";

const PageSignUp = ({ className = "" }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [activeTab, setActiveTab] = useState("student"); // Track the active tab

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
      // username: "",
      email: "",
      password: "",
      confirm_password: "",
      date_of_birth: "",
      is_host: activeTab,
    },
    validationSchema: activeTab === 'student' ? SignUpSchemaForStudent : SignUpSchemaForHost,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        // username: values.username,
        email: values.email,
        password: values.password,
        date_of_birth: values.date_of_birth,
        type: activeTab,
        phone: values.phone,
        // Include user type in payload
      };

      setSubmitting(true);
      dispatch(signUp(payload))
        .unwrap()
        .then(
          (response: {
            success: any;
            access_token: string;
            message: string;
          }) => {
            if (response.success) {
              localStorage.setItem("authToken", response?.access_token);
              successHandler(response?.message || "Registered successfully");
              dispatch(getCurrentUser())
                .unwrap()
                .then((res) => {
                  if (res?.success) {
                    if (res?.data?.email_verified) {
                      navigate("/");
                    } else {
                      navigate("/account");
                    }
                  }
                });
              resetForm();
            } else {
              console.log("Signup failed: ", response);
            }
          }
        )
        .catch((error: any) => {
          console.log("Error in signup: ", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <div className={`nc-PageSignUp ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Booking React Template</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {activeTab === "student" ? "Signup as Student" : "Signup as Host"}
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* Tabs for Student and Host */}
          <div className="grid grid-cols-2 justify-center space-x-4 mb-6">
            <ButtonThird
              className={`px-4 py-2 rounded-lg transition duration-300 ${
                activeTab === "student"
                  ? "bg-orange-500 text-white shadow-lg"
                  : "bg-transparent border-orange-500 border-2 text-black dark:text-neutral-300 "
              }`}
              onClick={() => setActiveTab("student")}
            >
              Student
            </ButtonThird>
            <ButtonThird
              className={`px-4 py-2 rounded-lg transition duration-300 ${
                activeTab === "host"
                  ? "bg-orange-500 text-white shadow-lg"
                  : "bg-transparent border-orange-500 border-2  dark:text-neutral-300"
              }`}
              onClick={() => setActiveTab("host")}
            >
              Host
            </ButtonThird>
          </div>

          {/* Conditional Rendering of Social Signup Buttons */}
          {activeTab === "student" && (
            <>
              <SocialAuth />
              <div className="relative text-center">
                <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                  OR
                </span>
                <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
              </div>
            </>
          )}

          {/* FORM */}
          <form
            onSubmit={formik.handleSubmit}
            className="grid grid-cols-1 gap-6"
          >
            <FormField
              label="First Name"
              name="first_name"
              type="text"
              placeholder="First Name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.first_name && formik.errors.first_name}
            />

            <FormField
              label="Last Name"
              name="last_name"
              type="text"
              placeholder="Last Name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.last_name && formik.errors.last_name}
            />

            {/* <FormField
              label="UserName"
              name="username"
              type="text"
              placeholder="Username"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.username && formik.errors.username}
            /> */}

            <FormField
              label="Email"
              name="email"
              type="email"
              placeholder="example@example.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && formik.errors.email}
            />

            {activeTab === "host" && (
              <div>
                <PhoneNumberInput
                  label="Phone/whatsapp:"
                  name="phone"
                  placeholder="+1 (123) 456-7890"
                  value={{
                    phone: formik.values.phone,
                    country_code: "",
                  }}
                  onChange={(value: { phone: any; country_code: any }) => {
                    formik.setFieldValue("phone", value.phone);
                    formik.setFieldValue("country_code", value.country_code);
                  }}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className="text-red-500">{formik.errors.phone}</div>
                )}
              </div>
            )}

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Password:
              </span>
              <div className="relative">
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-3 flex items-center text-neutral-700"
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="text-red-600 text-sm mt-1">
                  {formik.errors.password}
                </div>
              ) : null}
            </label>

            <FormField
              label="Confirm Password"
              name="confirm_password"
              type="password"
              placeholder="Confirm Password"
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.confirm_password &&
                formik.errors.confirm_password
              }
            />
            
            <FormField
              label="DOB"
              name="date_of_birth"
              placeholder="Date Of Birth"
              type="date"
              value={formik.values.date_of_birth}
              onChange={(value) => formik.setFieldValue("date_of_birth", value)} // Set the value manually for dates
              onBlur={formik.handleBlur}
              error={
                formik.touched.date_of_birth && formik.errors.date_of_birth
              }
            />

            <ButtonPrimary type="submit" loading={formik.isSubmitting}>
              Continue
            </ButtonPrimary>
          </form>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link className="text-orange-500" to="/login">
              Sign in
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
