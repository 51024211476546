import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "../../../utils/helpers/APIHelper";
import { RootState } from "../../store";
// import { SignInRequestPayload, SignUpRequestPayload } from "./AuthSliceTypes";
import ErrorHandler from "../../../utils/helpers/ErrorHandler";
import { ENDPOINTS } from "../../../API/apiConstants";
import {
  UpdateUserRequestBody,
  UserResponseBody,
  UserState,
  UpdateUserPasswordRequestBody,
} from "./UserSliceType";
import { SendMessagePayload } from "../ChatReducer/ChatSliceTypes";

const initialState: UserState = {
  currentUser: null,
  loading: false,
  authenticated: false,
  tenantType: "student",
  messages: null,
};

export const getCurrentUser = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  void,
  { state: RootState; rejectValue: any }
>("user/currentUser", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.LOGGGED_IN);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const updateUserAPI = createAsyncThunk<
  UserResponseBody, // Payload type for successful response
  UpdateUserRequestBody,
  { state: RootState; rejectValue: any }
>("auth/updateUser", async (payload, { rejectWithValue }) => {
  try {
    const response = await API.patch(ENDPOINTS.UPDATE_USER, payload);
    // Ensure the correct shape of the response is returned
    return response.data as UserResponseBody;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const updateUserWithImageAPI = createAsyncThunk(
  "user/updateWithImage",
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const response = await API.patch(ENDPOINTS.UPDATE_USER, formData);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUserEmailAsVerified = createAsyncThunk<
  any, // Payload type for successful response
  void,
  { state: RootState; rejectValue: any }
>("user/updateUserEmailAsVerified", async (_, { rejectWithValue }) => {
  try {
    const response = await API.patch(ENDPOINTS.UPDATE_USER_EMAIL_AS_VERFIED);
    // Ensure the correct shape of the response is returned
    return response;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const deleteUserAccount = createAsyncThunk<
  any, // Payload type for successful response
  any,
  { state: RootState; rejectValue: any }
>("user/deleteUserAccount", async (id, { rejectWithValue }) => {
  try {
    const response = await API.delete(`${ENDPOINTS.DELETE_USER}/${id}`);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateNotificationPreferences = createAsyncThunk<
  any, // Payload type for successful response
  any,
  { state: RootState; rejectValue: any }
>(
  "auth/updateNotificationPreferences",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await API.patch(
        ENDPOINTS.UPDATE_NOTIFICATION_PREFERENCE,
        payload
      );
      return response;
    } catch (error) {
      ErrorHandler(error);
      return rejectWithValue(error);
    }
  }
);

export const emailVerification = createAsyncThunk<
  any, // Type for the successful response
  any, // Updated to match the expected structure
  { state: RootState; rejectValue: any }
>("user/emailVerification", async (payload, { rejectWithValue }) => {
  try {
    const response = await API.post(`${ENDPOINTS.SEND_EMAIL}`, payload);
    return response; // Adjust according to your API response
  } catch (error) {
    console.error("Error sending reset email:", error);
    return rejectWithValue(error);
  }
});

export const createNewPassword = createAsyncThunk<
  any, // Payload type for successful response
  any, // Updated to include token
  { state: RootState; rejectValue: any }
>("auth/updatePassword", async ({ token, payload }, { rejectWithValue }) => {
  try {
    const response = await API.patch(
      `${ENDPOINTS.SEND_EMAIL}?reset_password_token=${token}`,
      payload
    );
    // Ensure the correct shape of the response is returned
    return response;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const appPayPal = createAsyncThunk<
  any, // Type for the successful response
  any, // Updated to match the expected structure
  { state: RootState; rejectValue: any }
>("user/appPayPal", async (payload, { rejectWithValue }) => {
  try {
    const response = await API.post(`${ENDPOINTS.ADD_PAYPAL}`, payload);
    return response; // Adjust according to your API response
  } catch (error) {
    console.error("Error sending reset email:", error);
    return rejectWithValue(error);
  }
});

export const editPayPal = createAsyncThunk<
  any, // Payload type for successful response
  any, // Input payload
  { state: RootState; rejectValue: any }
>("user/editPayPal", async (payload, { rejectWithValue }) => {
  const { id, user_paypal } = payload; // Extracting ID and email
  try {
    const response = await API.put(`/api/v1/user_paypal/${id}`, {
      user_paypal,
    });
    return response;
  } catch (error) {
    ErrorHandler(error); // Custom error handling
    return rejectWithValue(error);
  }
});

export const getPayPal = createAsyncThunk<
  any, // Payload type for successful response (you may want to type this more strictly)
  void,
  { state: RootState; rejectValue: any }
>("user/getPayPal", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.ADD_PAYPAL);
    return response; // Returning data directly, which contains user info
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const addCreditCard = createAsyncThunk<
  any,
  any,
  { state: RootState; rejectValue: any }
>("user/addCreditCard", async (payload, { rejectWithValue }) => {
  try {
    const response = await API.post(`${ENDPOINTS.ADD_CREDIT_CARD}`, payload);
    return response; // Return the response data on success
  } catch (error: any) {
    ErrorHandler(error);
    return (
      rejectWithValue(error.response?.data?.message) ||
      "Failed to add credit card."
    );
  }
});

// export const editCreditCard = createAsyncThunk<
//   any, // Payload type for successful response
//   any, // Input payload
//   { state: RootState; rejectValue: any }
// >("user/editCreditCard", async (payload, { rejectWithValue }) => {
//   const { id, user_paypal } = payload; // Extracting ID and email
//   try {
//     const response = await API.put(`/api/v1/user_paypal/${id}`, {
//       user_paypal,
//     });
//     return response;
//   } catch (error) {
//     ErrorHandler(error); // Custom error handling
//     return rejectWithValue(error);
//   }
// });

// export const getCreditCard = createAsyncThunk<
//   any, // Payload type for successful response (you may want to type this more strictly)
//   void,
//   { state: RootState; rejectValue: any }
// >("user/getCreditCard", async (_, { rejectWithValue }) => {
//   try {
//     const response = await API.get(ENDPOINTS.ADD);
//     return response; // Returning data directly, which contains user info
//   } catch (error) {
//     ErrorHandler(error);
//     return rejectWithValue(error);
//   }
// });




export const sendMessage = createAsyncThunk<
  any,
  SendMessagePayload,
  { state: RootState; rejectValue: string }
>(
  "user/sendMessaage",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await API.post(ENDPOINTS.SEND_MESSAGE(payload?.chat_id), payload);
      return response; 
    } catch (error: any) {
      ErrorHandler(error); 
      return rejectWithValue(
        error.response?.data?.message || "Failed to create chat."
      );
    }
  }
);

export const getMessage = createAsyncThunk<
  any,
  number,
  { state: RootState; rejectValue: string }
>(
  "user/getMessages",
  async (chatId , { rejectWithValue }) => {
    try {
      const response = await API.get(ENDPOINTS.GET_MESSAGE(chatId));
      return response;
    } catch (error: any) {
      ErrorHandler(error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch messages."
      );
    }
  }
);

export const bookingQueries = createAsyncThunk<
  any, 
  any,
  { state: RootState; rejectValue: string }
>(
  "user/bookingQueries",
  async ({ property_id, ...payload }, { rejectWithValue }) => {
    try {
      
      const response = await API.post(ENDPOINTS.ADD_QUERIES(property_id), payload);
      return response.data; 
    } catch (error: any) {
      ErrorHandler(error); 
      return rejectWithValue(error.message || "Failed to fetch booking queries");
    }
  }
);

export const createChat = createAsyncThunk<
  any, 
  any, 
  { state: RootState; rejectValue: string }
>(
  "user/createChat",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await API.post(`${ENDPOINTS.CREATE_CHAT}` ,payload); 
      return response; 
    } catch (error: any) {
      ErrorHandler(error); 
      return rejectWithValue(
        error.response?.data?.message || "Failed to create chat."
      );
    }
  }
); 








const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setTenantType: (state, action) => {
      state.tenantType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCurrentUser.fulfilled,
      (state, action: PayloadAction<UserResponseBody>) => {
        const userData = action?.payload?.data;
        state.loading = false;
        state.currentUser = {
          ...userData,
          email: userData.email || "example@mail.com",
          first_name: userData.first_name || "Unknown",
          last_name: userData.last_name || "Unknown",
          date_of_birth: userData.date_of_birth || "",
          phone: userData.phone || "",
          gender: userData.gender || "other",
          is_student: userData.is_student ?? false,
          is_host: userData.is_host ?? false,
          credit_cards: userData?.credit_cards
            ? {
                name: userData.credit_cards?.name || "",
                cc_number: userData.credit_cards?.cc_number || "",
                month: userData.credit_cards?.month || "",
                year: userData.credit_cards?.year || "",
                id: userData.credit_cards?.id || null,
              }
            : {
                name: "",
                cc_number: "",
                month: "",
                year: "",
                id: null,
              },
          paypal_info: userData?.paypal_info
            ? {
                email: userData.paypal_info?.email || "",
                id: userData.paypal_info?.id || null,
              }
            : {
                email: "",
                id: null,
              },
        };
        state.tenantType = userData.tenant_type || "student";
        state.authenticated = true;
      }
    );
    builder.addCase(emailVerification.fulfilled, (state) => {
      state.loading = false;
      // You can also handle any state changes based on the email verification response here
    });
    builder.addCase(updateUserAPI.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUserEmailAsVerified.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteUserAccount.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addMatcher(
      (action) =>
        [
          getCurrentUser.pending.type,
          updateUserAPI.pending.type,
          updateUserEmailAsVerified.pending.type,
          deleteUserAccount.pending.type,
          emailVerification.pending.type,
        ].includes(action.type),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      (action) =>
        [
          getCurrentUser.rejected.type,
          updateUserAPI.rejected.type,
          updateUserEmailAsVerified.rejected.type,
          deleteUserAccount.rejected.type,
          emailVerification.rejected.type,
        ].includes(action.type),
      (state) => {
        state.loading = false;
      }
    );
  },
});

export const { setTenantType } = userSlice.actions;

export default userSlice.reducer;
