import { MapPinIcon } from "@heroicons/react/24/solid";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import Label from "components/Label/Label";
import GoogleMapReact from "google-map-react";
import React, { FC, useEffect } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "redux/store";
import { useFormik } from "formik";
import {
  createProperties,
  getallupdateProperties,
  setId,
  updateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import { locationValidationSchema } from "utils/formSchema";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useSelector } from "react-redux";
import __countryListing from "../../data/jsons/__countryListing.json";
import { property } from "lodash";

export interface PageAddListing2Props { }

interface FormValues {
  title: string;
  country: string;
  street: string;
  city: string;
  state: string;
  zipcode: string | number;
}

const PageAddListing2: FC<PageAddListing2Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { globalId, propertyToCreate, PropertyToEdit } = useSelector(
    (state: RootState) => state.property
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  useEffect(() => {
    const propertyId = globalId || id;
    if (propertyId) {
      dispatch(getallupdateProperties({ propertyId }));
    }
  }, [dispatch, globalId, id]);

  const formik = useFormik<FormValues>({
    initialValues: {
      title: PropertyToEdit?.title || "",
      country: PropertyToEdit?.country || "",
      street: PropertyToEdit?.address || "",
      city: PropertyToEdit?.city || "",
      state: PropertyToEdit?.state || "",
      zipcode: PropertyToEdit?.zipcode || "",
    },
    validationSchema: locationValidationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const payload = {
        title: propertyToCreate?.title,
        property_category_id: propertyToCreate?.property_category_id,
        property_type_id: propertyToCreate?.property_type_id,
        address: `${values.street}`,
        city: values.city,
        state: values.state,
        country: values.country,
        zipcode: values.zipcode,
        ...(id ? { id: id } : {})
      };

      setSubmitting(true);

      if (id) {
        dispatch(updateProperties({ propertyId: id, property: payload }))
          .unwrap()
          .then((response) => {
            if (response?.success) {
              successHandler(response?.message || "Updated successfully");
              navigate(`/add-listing-3?id=${id}`);
              resetForm();
            } else {
              console.log("Update failed: ", response);
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        dispatch(createProperties({ property: payload }))
          .unwrap()
          .then((response) => {
            if (response?.success) {
              successHandler(response?.message || "Created successfully");
              dispatch(setId(response.property.id));
              navigate(`/add-listing-3?id=${response.property.id}`);
              resetForm();
            } else {
              console.log("Creation failed: ", response);
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },

  });

  return (
    <CommonLayout index="02" nextHref="/add-listing-3" backtHref="/add-listing-1">
      <>
        <h2 className="text-2xl font-semibold">Your place location</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* FORM */}
        <form onSubmit={formik.handleSubmit}>
          <div className="space-y-8">
            <ButtonSecondary>
              <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
              <span className="ml-3">Use current location</span>
            </ButtonSecondary>

            {/* Country */}
            <FormItem label="Country/Region">
              <Select
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select a country</option>
                {__countryListing.map((country) => (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </Select>
              {formik.touched.country && formik.errors.country ? (
                <div className="text-red-600">{formik.errors.country}</div>
              ) : null}
            </FormItem>

            {/* Street */}
            <FormItem label="Street">
              <Input
                name="street"
                value={formik.values.street}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter street"
              />
              {formik.touched.street && formik.errors.street ? (
                <div className="text-red-600">{formik.errors.street}</div>
              ) : null}
            </FormItem>

            {/* <FormItem label="Room number (optional)">
              <Input />
            </FormItem> */}

            {/* City, State, Postal Code */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
              <FormItem label="City">
                <Input
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.city && formik.errors.city ? (
                  <div className="text-red-600">{formik.errors.city}</div>
                ) : null}
              </FormItem>

              <FormItem label="State">
                <Input
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.state && formik.errors.state ? (
                  <div className="text-red-600">{formik.errors.state}</div>
                ) : null}
              </FormItem>

              <FormItem label="Zipcode">
                <Input
                  name="zipcode"
                  value={formik.values.zipcode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.zipcode && formik.errors.zipcode ? (
                  <div className="text-red-600">{formik.errors.zipcode}</div>
                ) : null}
              </FormItem>
            </div>

            {/* Map */}
            <div>
              <Label>Detailed address</Label>
              <span className="block mt-1 text-sm text-neutral-500 dark:text-neutral-400">
                1110 Pennsylvania Avenue NW, Washington, DC 20230
              </span>
              <div className="mt-4">
                <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
                  <div className="rounded-xl overflow-hidden">
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY",
                      }}
                      defaultZoom={15}
                      defaultCenter={{ lat: 55.9607277, lng: 36.2172614 }}
                    >
                      <LocationMarker lat={55.9607277} lng={36.2172614} />
                    </GoogleMapReact>
                  </div>
                </div>
              </div>
            </div>

            {/* Submit buttons */}
            <div className="flex justify-end space-x-5">
              <ButtonSecondary href={`/add-listing-1?id=${globalId || id}`}>Go back</ButtonSecondary>
              <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
                {formik.isSubmitting ? "Loading..." : "Continue"}
              </ButtonPrimary>
            </div>
          </div>
        </form>
      </>
    </CommonLayout>
  );
};

export default PageAddListing2;
