import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store"; // Adjust the path as necessary
import { useNavigate } from "react-router-dom"; // Assuming you want to redirect after success
import { emailVerification } from "../../redux/reducers/UserSlice/UserSlice";
import { toast } from "react-toastify";
import Button from "shared/Button/Button";
import ButtonPrimary from "shared/Button/ButtonPrimary";

// Validation Schema
const emailValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const RequestPasswordReset: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center min-h-screen bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <div className="w-full max-w-md bg-white dark:bg-neutral-900 rounded-lg shadow-md p-8">
        <h2 className="text-2xl text-orange-500 text-center mb-6 dark:text-orange-400">
          Forgot Password
        </h2>

        <p className="text-center mb-4 dark:text-gray-300">
          Enter your email below and we'll send you a link to reset your
          password.
        </p>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={emailValidationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
              dispatch(emailVerification({ user: { email: values.email } }))
                .unwrap()
                .then((res) => {
                  // Check if the API call was successful
                  if (res?.success) {
                    toast.success("Reset link sent to your email!");
                    // navigate("/forgot_password");
                  } else {
                    if (res?.error && res.error.includes("Email not found")) {
                      toast.error(
                        "Email not found. Please enter a valid email."
                      );
                    } else {
                      toast.error(
                        res?.message ||
                          "Failed to send reset link. Please try again."
                      );
                    }
                  }
                })
                .catch((error: any) => {
                  const errorMessage =
                    error?.response?.data?.error ||
                    "Failed to send reset link.";

                  if (errorMessage.includes("Email not found")) {
                    toast.error("Email not found. Please enter a valid email.");
                  } else {
                    toast.error(errorMessage);
                  }
                });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              {/* Email Field */}
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-orange-500 dark:text-orange-400"
                >
                  Email Address
                </label>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  className="w-full px-3 py-2 mt-1 border rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              {/* Submit Button */}
              <ButtonPrimary
                type="submit"
                className="w-full"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Sending..." : "Send Reset Link"}
              </ButtonPrimary>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RequestPasswordReset;
