import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik, FieldArray, FormikProvider } from "formik";
import * as Yup from "yup";
import { AppDispatch, RootState } from "redux/store";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import {
  updateProperties,
  getallupdateProperties,
  getRoomAmenitiesProperty,
  getRoomFeaturesProperty,
  getBedType,
  getRoomType,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import { roomRuleValidationSchema } from "utils/formSchema";
import CheckboxV2 from "shared/Checkbox/CheckboxV2";
import {
  BedType,
  BedTypeResponse,
  RoomType,
} from "../../redux/reducers/Properties/PropertiesSliceTypes";

export interface PageAddListing11Props {}

const PageAddListing11: FC<PageAddListing11Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const navigate = useNavigate();
  const { globalId } = useSelector((state: RootState) => state.property);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const initialRoomValues = {
    id: "",
    max_guests: "",
    price_per_night: "",
    status: "",
    booking_start: "",
    booking_end: "",
    description: "",
    bed_type_id: "",
    size: "",
    room_type_id: "",
    room_amenities_attributes: [],
    room_features_attributes: [],
  };

  const [initialValues, setInitialValues] = useState({
    rooms_attributes: [initialRoomValues],
  });

  const [amenities, setAmenities] = useState([]);
  const [features, setFeatures] = useState([]);
  const [checkedAmenities, setCheckedAmenities] = useState<{
    [roomIndex: number]: string[];
  }>({});
  const [checkedFeatures, setCheckedFeatures] = useState<{
    [roomIndex: number]: string[];
  }>({});
  const [bedTypes, setBedTypes] = useState<BedType[]>([]);
  const [roomTypes, setRoomTypes] = useState<RoomType[]>([]);
  const [totalRooms, setTotalRooms] = useState<number | null>(null);

  useEffect(() => {
    if (globalId || id) {
      const fetchPropertyData = async () => {
        const response: any = await dispatch(
          getallupdateProperties({ propertyId: globalId || id })
        );

        setTotalRooms(response?.payload?.property?.total_rooms);

        if (response.payload?.property?.rooms) {
          const mappedRooms = response.payload.property.rooms.map(
            (room: any) => ({
              id: room.id,
              max_guests: room.max_guests?.toString(),
              price_per_night: room.price_per_night?.toString(),
              status: room.status,
              booking_start: room.booking_start
                ? room.booking_start.split("T")[0]
                : "",
              booking_end: room.booking_end
                ? room.booking_end.split("T")[0]
                : "",
              description: room.description,
              bed_type_id: room.bed_type?.id?.toString(),
              size: room.size?.toString(),
              room_type_id: room.room_type?.id?.toString(),
            })
          );

          // Remove duplicates based on 'description' or another unique field
          const uniqueRooms = Array.from(
            new Map(
              mappedRooms.map((room: any) => [room.description, room])
            ).values()
          ) as {
            max_guests: string;
            price_per_night: string;
            status: string;
            booking_start: string;
            booking_end: string;
            description: string;
            bed_type_id: string;
            size: string;
            room_type_id: string;
            id: Number;
          }[];

          setInitialValues({
            rooms_attributes:
              uniqueRooms.length > 0
                ? (uniqueRooms as any)
                : [initialRoomValues],
          });
        }
      };
      fetchPropertyData();
    }
  }, [dispatch, globalId, id]);

  const handleCheckboxChange = (
    id: string,
    type: "amenity" | "feature",
    roomIndex: number
  ) => {
    if (type === "amenity") {
      setCheckedAmenities((prev) => ({
        ...prev,
        [roomIndex]: prev[roomIndex]?.includes(id)
          ? prev[roomIndex].filter((item) => item !== id)
          : [...(prev[roomIndex] || []), id],
      }));
    } else {
      setCheckedFeatures((prev) => ({
        ...prev,
        [roomIndex]: prev[roomIndex]?.includes(id)
          ? prev[roomIndex].filter((item) => item !== id)
          : [...(prev[roomIndex] || []), id],
      }));
    }
  };

  const validationSchema = Yup.object({
    rooms_attributes: Yup.array().of(roomRuleValidationSchema),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const payload = {
        id: globalId || id,
        rooms_attributes: values.rooms_attributes.map((room, index) => ({
          id: room.id,
          max_guests: parseInt(room.max_guests),
          price_per_night: parseFloat(room.price_per_night),
          status: room.status,
          booking_start: room.booking_start,
          booking_end: room.booking_end,
          description: room.description,
          bed_type_id: room.bed_type_id,
          size: parseFloat(room.size),
          room_type_id: room.room_type_id,
          room_amenities_attributes: (checkedAmenities[index] || []).map(
            (amenity_id) => ({ amenity_id: amenity_id })
          ),
          room_features_attributes: (checkedFeatures[index] || []).map(
            (feature_id) => ({ feature_id: feature_id })
          ),
        })),
      };
      console.log("payloadpayload", payload);
      setSubmitting(true);
      dispatch(updateProperties({ property: payload }))
        .unwrap()
        .then((response) => {
          if (response) {
            successHandler(response?.message || "Updated successfully");
            resetForm();
            navigate(`/add-listing-12?id=${response.property.id}`);
          }
        })
        .catch((error) => {
          console.error("Error updating property: ", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    dispatch(getRoomFeaturesProperty())
      .unwrap()
      .then((response) => setFeatures(response.data)) // Access `data` key here
      .catch((error) => console.error("Error fetching features:", error));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRoomAmenitiesProperty())
      .unwrap()
      .then((response) => setAmenities(response.data)) // Access `data` key here
      .catch((error) => console.error("Error fetching features:", error));
  }, [dispatch]);

  useEffect(() => {
    const fetchBedTypes = async () => {
      try {
        const response = await dispatch(getBedType()).unwrap();
        if (response.success) {
          setBedTypes(response.data); // Set the bed types from the response
        } else {
          console.error("Failed to fetch bed types:", response.message);
        }
      } catch (error) {
        console.error("Error fetching bed types:", error);
      }
    };

    const fetchRoomTypes = async () => {
      try {
        const response = await dispatch(getRoomType()).unwrap();
        if (response.success) {
          setRoomTypes(response.data); // Set the room types from the response
        } else {
          console.error("Failed to fetch room types:", response.message);
        }
      } catch (error) {
        console.error("Error fetching room types:", error);
      }
    };
    fetchBedTypes();
    fetchRoomTypes();
  }, [dispatch]);

  return (
    <CommonLayout
      index="11"
      nextHref="/add-listing-13"
      backtHref="/add-listing-11"
    >
      <>
        <h2 className="text-2xl font-semibold">Room Details</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Provide detailed information about your property to help guests make
          informed decisions.
        </span>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <FieldArray
              name="rooms_attributes"
              render={(arrayHelpers) => {
                return (
                  <>
                    {formik.values.rooms_attributes.map((room, index) => (
                      <div
                        key={index}
                        className={`${
                          index > 0
                            ? "mt-12 pt-8 border-t border-neutral-200"
                            : ""
                        }`}
                      >
                        <h3 className="text-xl font-bold text-neutral-900 dark:text-neutral-100 mb-6">
                          Room {index + 1}
                        </h3>
                        <FormItem label="Maximum Guests" className="mt-4">
                          <Input
                            type="number"
                            name={`rooms_attributes[${index}].max_guests`}
                            placeholder="Enter maximum number of guests"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={room.max_guests}
                          />
                        </FormItem>
                        <FormItem label="Price per Month" className="mt-4">
                          <Input
                            type="number"
                            name={`rooms_attributes[${index}].price_per_night`}
                            placeholder="0.00"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={room.price_per_night}
                          />
                        </FormItem>
                        <FormItem label="Status" className="mt-4">
                          <Select
                            name={`rooms_attributes[${index}].status`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={room.status}
                          >
                            <option value="">Select status</option>
                            <option value="available">Available</option>
                            <option value="booked">Booked</option>
                            <option value="maintenance">
                              Under Maintenance
                            </option>
                          </Select>
                        </FormItem>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                          <FormItem label="Booking Start Date" className="mt-4">
                            <Input
                              type="date"
                              name={`rooms_attributes[${index}].booking_start`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={room.booking_start}
                            />
                          </FormItem>
                          <FormItem label="Booking End Date" className="mt-4">
                            <Input
                              type="date"
                              name={`rooms_attributes[${index}].booking_end`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={room.booking_end}
                            />
                          </FormItem>
                        </div>
                        <FormItem label="Description" className="mt-4">
                          <textarea
                            className="block w-full h-32 px-4 py-3 text-sm rounded-2xl border-neutral-200"
                            name={`rooms_attributes[${index}].description`}
                            placeholder="Enter room description"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={room.description}
                          />
                        </FormItem>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
                          <FormItem label="Bed Type" className="mt-4">
                            <Select
                              name={`rooms_attributes[${index}].bed_type_id`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={room.bed_type_id}
                            >
                              <option value="">Select bed type</option>
                              {bedTypes.map((bedType) => (
                                <option key={bedType.id} value={bedType.id}>
                                  {bedType.name}
                                </option>
                              ))}
                            </Select>
                          </FormItem>
                          <FormItem label="Room Size (sq ft)" className="mt-4">
                            <Input
                              type="number"
                              name={`rooms_attributes[${index}].size`}
                              placeholder="Enter room size"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={room.size}
                            />
                          </FormItem>
                          <FormItem label="Room Type" className="mt-4">
                            <Select
                              name={`rooms_attributes[${index}].room_type_id`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={room.room_type_id}
                            >
                              <option value="">Select room type</option>
                              {roomTypes.map((roomType) => (
                                <option key={roomType.id} value={roomType.id}>
                                  {roomType.name}
                                </option>
                              ))}
                            </Select>
                          </FormItem>
                        </div>
                        <FormItem>
                          <h2 className="text-2xl font-semibold my-5">
                            Amenities
                          </h2>
                          <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                            {amenities.map((category: any) =>
                              category?.amenities.map((amenity: any) => (
                                <CheckboxV2
                                  key={amenity.id}
                                  id={amenity.id.toString()}
                                  label={amenity.name}
                                  name={`rooms_attributes[${index}].room_amenities_attributes`}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      amenity.id.toString(),
                                      "amenity",
                                      index
                                    )
                                  }
                                  checked={
                                    checkedAmenities[index]?.includes(
                                      amenity.id.toString()
                                    ) || false
                                  }
                                />
                              ))
                            )}
                          </div>
                        </FormItem>
                        <FormItem>
                          <h2 className="text-2xl font-semibold my-5">
                            Features
                          </h2>
                          <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                            {features.map((feature: any) => (
                              <CheckboxV2
                                key={feature.id}
                                id={feature.id.toString()}
                                label={feature.name}
                                name={`rooms_attributes[${index}].room_features_attributes`}
                                onChange={() =>
                                  handleCheckboxChange(
                                    feature.id.toString(),
                                    "feature",
                                    index
                                  )
                                }
                                checked={
                                  checkedFeatures[index]?.includes(
                                    feature.id.toString()
                                  ) || false
                                }
                              />
                            ))}
                          </div>
                        </FormItem>
                        {index > 0 && (
                          <ButtonPrimary
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                            className="my-5"
                          >
                            Remove Room
                          </ButtonPrimary>
                        )}
                        {totalRooms && (index + 1 < totalRooms) && (
                          <ButtonPrimary
                            type="button"
                            onClick={() => arrayHelpers.push(initialRoomValues)}
                            className="my-5"
                          >
                            Add Another Room
                          </ButtonPrimary>
                        )}
                      </div>
                    ))}
                    <div className="flex justify-end space-x-5 mt-5">
                      <ButtonSecondary
                        href={`/add-listing-10?id=${globalId || id}`}
                      >
                        Go back
                      </ButtonSecondary>
                      <ButtonPrimary
                        type="submit"
                        disabled={formik.isSubmitting}
                      >
                        {formik.isSubmitting ? "Loading..." : "Continue"}
                      </ButtonPrimary>
                    </div>
                  </>
                );
              }}
            />
          </form>
        </FormikProvider>
      </>
    </CommonLayout>
  );
};

export default PageAddListing11;
