import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getBookings, getRequestBookings } from "../../../redux/reducers/Properties/PropertiesSlice";
import { AppDispatch, RootState } from "redux/store";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import StayCardV4 from "components/StayCard/StayCardV4";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

// Types
interface BookingData {
    id: string;
    [key: string]: string | number | boolean;
}

const MyRequest: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();

    // State
    const [page, setPage] = useState(1);
    const [bookingData, setBookingData] = useState<BookingData[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    // URL and Redux state
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const { globalId, metaData } = useSelector((state: RootState) => state.property);
    const { currentUser, loading: userLoading } = useSelector((state: RootState) => state.user);
    const propertyId = globalId || id;

    // Fetch bookings with error handling
    const fetchBookings = useCallback(async (pageNumber: number) => {
        try {
            setIsLoading(true);
            const response = await dispatch(getRequestBookings(pageNumber));

            if (response?.payload?.bookings) {
                setBookingData(response.payload.bookings);
                return true;
            }
            return false;
        } catch (error) {
            console.error("Error fetching bookings:", error);
            return false;
        } finally {
            setIsLoading(false);
        }
    }, [dispatch]);

    // Pagination handlers
    const handleLoadMore = useCallback(async () => {
        if (metaData?.next_page && !isLoading) {
            const nextPage = page + 1;
            const success = await fetchBookings(nextPage);
            if (success) {
                setPage(nextPage);
            }
        }
    }, [metaData?.next_page, page, isLoading, fetchBookings]);

    const handleGoBack = useCallback(async () => {
        if (metaData?.prev_page && page > 1 && !isLoading) {
            const prevPage = page - 1;
            const success = await fetchBookings(prevPage);
            if (success) {
                setPage(prevPage);
            }
        }
    }, [metaData?.prev_page, page, isLoading, fetchBookings]);

    useEffect(() => {
        fetchBookings(1);
    }, [fetchBookings]);


    if (isLoading && !bookingData.length) {
        return (
            <div className="nc-PageHome relative overflow-hidden">
                <div className="container relative">
                    <div className="min-h-screen flex items-center justify-center">
                        <div className="relative">
                            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-primary-500"></div>
                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-b-4 border-secondary-500"></div>
                            </div>
                        </div>
                        <div className="absolute mt-24 text-center">
                            <p className="text-lg font-semibold text-neutral-900 dark:text-neutral-200 mt-8">
                                Loading listings...
                            </p>
                            <p className="text-sm text-neutral-500 dark:text-neutral-400 mt-2">
                                Please wait while we fetch the properties
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
                <div className="relative py-16">
                    <BackgroundSection />

                    {/* Navigation buttons */}

                    {/* Bookings grid */}
                    {bookingData.length > 0 ? (
                        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                            {bookingData.map((data) => (
                                <StayCardV4
                                    key={data.id}
                                    booking={data}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="text-center py-10">
                            <h3 className="text-2xl font-semibold">No bookings found</h3>
                            <p className="text-gray-500 mt-2">There are currently no bookings to display.</p>
                        </div>
                    )}

                    <div className="flex mt-16 justify-center items-center space-x-4">
                        <ButtonPrimary
                            onClick={handleGoBack}
                            disabled={!metaData?.prev_page || isLoading}
                            className="px-6"
                        >
                            Previous Page
                        </ButtonPrimary>

                        <ButtonPrimary
                            onClick={handleLoadMore}
                            disabled={!metaData?.next_page || isLoading}
                            className="px-6"
                        >
                            Next Page
                        </ButtonPrimary>
                    </div>

                    {/* Loading indicator for pagination */}
                    {isLoading && (
                        <div className="flex justify-center mt-6">
                            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-500"></div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MyRequest;
