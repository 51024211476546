import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import {
  getallupdateProperties,
  getPropertyCategories,
  getPropertyType,
  setPropertyToCreate,
  updateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useLocation, useNavigate } from "react-router-dom";
import { listingCategoriesSchema } from "utils/formSchema";
import FormField from "shared/FormField/FormField";

export interface PageAddListing1Props { }

const PageAddListing1: FC<PageAddListing1Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [initialValues, setInitialValues] = useState({
    title: "",
    property_category: "",
    property_type_id: "",
  });

  // State for filtered property types
  const [filteredPropertyTypes, setFilteredPropertyTypes] = useState<any[]>([]);

  const { globalId, propertiesCategories, propertyTypes } = useSelector(
    (state: RootState) => state.property
  );

  // Formik initialization
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: listingCategoriesSchema,
    onSubmit: (values, { setSubmitting }) => {
      const payload = {
        title: values.title,
        property_category_id: values.property_category,
        property_type_id: values.property_type_id,
        ...(id ? { id: id } : {})
      };

      setSubmitting(true);
      if (!id) {
        dispatch(setPropertyToCreate(payload));
        navigate('/add-listing-2', { state: values });
        setSubmitting(false);
        return;
      }

      dispatch(updateProperties({ property: payload }))
        .unwrap()
        .then((response) => {
          if (response?.success) {
            navigate(`/add-listing-2?id=${response.property.id}`, { state: values });
          } else {
            console.log("Operation failed: ", response);
          }
        })
        .catch((error) => {
          console.error("Error in operation: ", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    dispatch(getPropertyCategories());
    dispatch(getPropertyType());
  }, [dispatch]);

  useEffect(() => {
    const propertyId = globalId || id;
    if (propertyId) {
      dispatch(getallupdateProperties({ propertyId }))
        .unwrap()
        .then((response) => {
          if (response?.success && response.property) {
            // Prefill form with fetched data
            setInitialValues({
              title: response.property.title || "",
              property_category: response.property.property_category.id || "",
              property_type_id: response.property.property_type.id || "",
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching property data:", error);
        });
    }
  }, [dispatch, globalId, id]);


  useEffect(() => {
    if (Array.isArray(propertyTypes)) {
      setFilteredPropertyTypes(propertyTypes);
    } else {
      setFilteredPropertyTypes([]);
    }
  }, [propertyTypes]);

  return (
    <CommonLayout
      index="01"
      backtHref="/add-listing-1"
      nextHref="/add-listing-2"
    >
      <>
        <h2 className="text-2xl font-semibold">Choosing listing categories</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <form onSubmit={formik.handleSubmit}>
          <div className="space-y-8">
            <FormItem desc="Select a property category from the available options.">
              <FormField
                label="Choose a property Category"
                name="property_category"
                type="select"
                value={formik.values.property_category}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldValue("property_type_id", "");
                }}
                onBlur={formik.handleBlur}
                options={propertiesCategories?.map((item) => ({
                  value: String(item?.id),
                  label: item.name,
                })) || []}
                error={
                  formik.touched.property_category &&
                    formik.errors.property_category
                    ? formik.errors.property_category
                    : undefined
                }
              />
            </FormItem>

            <FormItem
              label="Place name"
              desc="A catchy name usually includes: House name + Room name + Featured property + Tourist destination"
            >
              <Input
                placeholder="Place name"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.title && formik.errors.title && (
                <div className="text-red-500">{formik.errors.title}</div>
              )}
            </FormItem>

            <FormItem
              label="Rental form"
              desc="Entire place: Guests have the whole place to themselves—there's a private entrance and no shared spaces."
            >
              <FormField
                label="Choose a property type"
                name="property_type_id"
                type="select"
                value={formik.values.property_type_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={filteredPropertyTypes?.map((type) => ({
                  value: String(type.id),
                  label: type.name,
                })) || []}
                error={
                  formik.touched.property_type_id &&
                    formik.errors.property_type_id
                    ? formik.errors.property_type_id
                    : undefined
                }
              />
            </FormItem>
          </div>

          <div className="flex justify-end space-x-5 my-5">
            <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
              {formik.isSubmitting ? "Loading..." : "Continue"}
            </ButtonPrimary>
          </div>
        </form>
      </>
    </CommonLayout>
  );
};

export default PageAddListing1;
