import React from "react";
import moment from "moment";
import { Chat } from "../../redux/reducers/ChatReducer/ChatSliceTypes";
import { User } from "../../redux/reducers/UserSlice/UserSliceType";

interface ChatListProps {
  currentUser: User | null
  chats: any;
  setSelectedChat: React.Dispatch<React.SetStateAction<Chat | null>>
  getAllMessages: (chatId: number) => void
}

const ChatList: React.FC<ChatListProps> = ({ chats, setSelectedChat, getAllMessages, currentUser }) => {
  
  const finalChats = chats?.map((chat: any) => chat.senderId === currentUser?.id ? {...chat, show: chat.receiver} :  {...chat, show: chat.sender} )

  const getName = (name: string) => {
    const modifiedName = name[0]?.toUpperCase() + name?.slice(1);
    return modifiedName;
  };

  const getLastMessage = (lastMessage: string) => {
    return lastMessage ? lastMessage : null;
  };

  const getMessageTime = (time: string) => {
    return time ? moment(time)?.fromNow() : null;
  };

  const chatHandler = (chat: any) => {
    setSelectedChat(chat)
    getAllMessages(chat.id)
  }

  return (
    <div className="mt-6 max-h-[calc(100vh-100px)] overflow-y-auto overflow-x-hidden">
    {finalChats.map((chat: any, index: any) => (
      <button
        key={index}
        className="w-full flex items-center py-4 px-2 hover:bg-neutral-200 dark:hover:bg-neutral-700 cursor-pointer border-b border-neutral-300 dark:border-neutral-700 focus:outline-none"
        onClick={() => chatHandler(chat)}
      >
        <img
          src={chat.show.image}
          alt={chat.show.name}
          className="w-10 h-10 rounded-full"
        />
        <div className="ml-4 flex-1 min-w-0" style={{ width: "100%" }}>
          <div className="flex items-center justify-between">
            <h3 className="text-base font-semibold text-neutral-900 dark:text-neutral-100 truncate">
              {getName(chat.show.name)}
            </h3>
            <p className="text-xs text-neutral-500 dark:text-neutral-400 ml-2 whitespace-nowrap">
              {getMessageTime(chat.lastMessageTime)}
            </p>
          </div>
          <p className="text-left text-sm text-neutral-500 dark:text-neutral-400 truncate">
            {getLastMessage(chat.lastMessage)}
          </p>
        </div>
      </button>
    ))}
  </div>
  

  );
};

export default ChatList;
