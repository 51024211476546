import React, { FC } from "react";

export interface CheckboxProps {
  id?: string | number;
  label?: string;
  subLabel?: string;
  className?: string;
  name: string;
  checked: boolean; // Ensure checked prop is required
  onChange: (checked: boolean, id?: string | number | any) => void; // Ensure onChange is passed
}

const CheckboxV2: FC<CheckboxProps> = ({
  id,
  subLabel = "",
  label = "",
  name,
  className = "",
  onChange,
  checked, // Controlled by parent
}) => {
  // Hook to connect Formik field with the checkbox
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    // Trigger onChange callback to update parent component's state
    onChange(isChecked, id);
  };

  return (
    <div className={`flex text-sm sm:text-base ${className}`}>
      <input
        id={String(id) || name}
        name={name}
        type="checkbox"
        className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700 dark:checked:bg-primary-500 focus:ring-primary-500"
        checked={checked} // Control checked state from parent
        onChange={handleChange}
      />
      {label && (
        <label
          htmlFor={String(id) || name}
          className="ml-3.5 flex flex-col flex-1 justify-center"
        >
          <span className="text-neutral-900 dark:text-neutral-100">
            {label}
          </span>
          {subLabel && (
            <p className="mt-1 text-neutral-500 dark:text-neutral-400 text-sm font-light">
              {subLabel}
            </p>
          )}
        </label>
      )}
    </div>
  );
};

export default CheckboxV2;
