import React, { FC, useEffect, useState } from "react";
import CheckboxV2 from "shared/Checkbox/CheckboxV2";
import CommonLayout from "./CommonLayout";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../redux/store";
import { Formik, Form } from "formik";
import {
  getallupdateProperties,
  getFeaturesProperty,
  updateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface PageAddListing5Props { }

const PageAddListing5: FC<PageAddListing5Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [checkedItems, setCheckedItems] = useState<string[]>([]); // Holds selected IDs
  const navigate = useNavigate();
  const { globalId, featuresCategories } = useSelector(
    (state: RootState) => state.property
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const initialValues = {
    features: [], // Formik initial value
  };

  // Update the checkbox state based on user input
  const handleCheckboxChange = (isChecked: boolean, id?: string | number) => {
    const idString = id?.toString(); // Convert the id to a string to handle it uniformly
    if (isChecked && idString) {
      // Add the checked ID
      setCheckedItems((prev) => [...prev, idString]);
    } else if (!isChecked && idString) {
      // Remove unchecked ID
      setCheckedItems((prev) => prev.filter((item) => item !== idString));
    }
  };

  // Prepare and send the form data on submit
  const handleSubmit = (values: { features: any }) => {
    const updatedFeatures = checkedItems.map((item) => ({
      feature_id: item,
    }));

    const payload = {
      id: globalId || id, // Make sure the correct ID is sent
      property_features_attributes: updatedFeatures, // Send updated features
    };

    dispatch(updateProperties({ property: payload }))
      .unwrap()
      .then((response) => {
        successHandler(response?.message || "Updated successfully");
        navigate(`/add-listing-6?id=${response.property.id}`);
      })
      .catch((error) => {
        console.error("Error updating features: ", error);
      });
  };

  // Fetch features categories for the form
  useEffect(() => {
    dispatch(getFeaturesProperty());
  }, [dispatch]);

  // Load existing property data for editing (prefill form)
  useEffect(() => {
    const propertyId = globalId || id;
    if (propertyId) {
      dispatch(getallupdateProperties({ propertyId }))
        .unwrap()
        .then((response) => {
          // Prefill features, ensuring no duplicates
          const prefilledFeatures = response?.property?.features?.map(
            (feature: any) => feature.id.toString()
          );
          setCheckedItems(prefilledFeatures || []); // Set the checked items based on the prefilled data
        });
    }
  }, [dispatch, globalId, id]);

  return (
    <CommonLayout
      index="05"
      backtHref="/add-listing-3"
      nextHref="/add-listing-5"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Features</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Many customers have searched for accommodation based on features
            criteria
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <div className="space-y-8">
                {/* Map the features directly from the API response */}
                <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                  {featuresCategories?.map((feature: any) => (
                    <div key={feature?.id}>
                      <CheckboxV2
                        id={feature?.id}
                        label={feature?.name}
                        name={feature?.name}
                        onChange={handleCheckboxChange} // Pass handleCheckboxChange here
                        checked={checkedItems.includes(feature?.id.toString())} // Control checked state based on checkedItems array
                      />
                    </div>
                  ))}
                </div>
                {errors.features && touched.features && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors.features}
                  </div>
                )}
              </div>
              <div className="flex justify-end space-x-5">
                <ButtonSecondary href={`/add-listing-4?id=${globalId || id}`}>Go back</ButtonSecondary>
                <ButtonPrimary type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Loading..." : "Continue"}
                </ButtonPrimary>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </CommonLayout>
  );
};

export default PageAddListing5;
