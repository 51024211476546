import Glide from "@glidejs/glide";
import useNcId from "hooks/useNcId";
import React, { FC, useEffect, useMemo, useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import NextPrev from "shared/NextPrev/NextPrev";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { getallupdateProperties } from "../../redux/reducers/Properties/PropertiesSlice";
import { GallerySliderProps, PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";
import { toast } from "react-toastify";
import ErrorHandler from "utils/helpers/ErrorHandler";

const GallerySlider: FC<GallerySliderProps> = ({
  className = "",
  galleryImgs,
  ratioClass = "aspect-w-4 aspect-h-3",
  uniqueID = "uniqueID",
}) => {
  const UNIQUE_CLASS = `gallerySlider__${uniqueID}` + useNcId();
  const [propertyData, setPropertyData] = useState<PropertyToEdit | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch<AppDispatch>();
  const { globalId } = useSelector((state: RootState) => state.property);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const propertyId = globalId || id;
  const href = `/listing-stay-detail?id=${propertyId}`;


  useEffect(() => {
    const fetchPropertyData = async () => {
      setLoading(true);
  
      if (propertyId || id) {
        try {
          const response = await dispatch(getallupdateProperties({ propertyId })).unwrap();
  
          // Check for property data in response
          if (response?.property) {
            setPropertyData(response.property);
            toast.success("Property data loaded successfully!");
          } else {
            toast.error("Failed to load property data: Data not found.");
          }
        } catch (error) {
          console.error("Error fetching property data:", error);
          toast.error("Error fetching property data. Please try again later.");
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error("Invalid property ID.");
      }
    };
  
    fetchPropertyData();
  }, [propertyId, id, dispatch]);
  

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: 1,
      gap: 0,
      keyboard: false,
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    const element = document.querySelector(`.${UNIQUE_CLASS}`);

    if (element && galleryImgs.length > 0) {
      const timeoutId = setTimeout(() => {
        MY_GLIDEJS?.mount();
      }, 3000);

      return () => clearTimeout(timeoutId); // Clean up the timeout on unmount or when dependencies change
    } else {
      console.warn("Element not found or gallery images are empty.");
    }
  }, [MY_GLIDEJS, UNIQUE_CLASS, galleryImgs]);

  const renderDots = () => {
    return (
      <div
        className="glide__bullets flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5"
        data-glide-el="controls[nav]"
      >
        {galleryImgs.map((_, i) => (
          <button
            className="glide__bullet w-1.5 h-1.5 rounded-full bg-neutral-300"
            key={i}
            data-glide-dir={`=${i}`}
          />
        ))}
      </div>
    );
  };

  const renderSliderGallery = () => {
    return (
      <div className={`${UNIQUE_CLASS} relative group overflow-hidden`}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {galleryImgs.map((item, index) => (
              <li key={index} className="glide__slide">
                <Link to={href} className={`block ${ratioClass}`}>
                  <NcImage src={item} />
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* DOTS */}
        <div className="absolute -bottom-4 inset-x-0 h-10 bg-gradient-to-t from-neutral-900"></div>
        {renderDots()}

        {/* NAV */}
        <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between">
          <NextPrev className="w-full justify-between" btnClassName="w-8 h-8" />
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-GallerySlider ${className}`} data-nc-id="GallerySlider">
      {renderSliderGallery()}
    </div>
  );
};

export default GallerySlider;
