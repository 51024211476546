import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { forgotValidationSchema } from "utils/formSchema";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { useNavigate, useLocation } from "react-router-dom";
import { createNewPassword } from "../../redux/reducers/UserSlice/UserSlice";
import { toast } from "react-toastify";

interface ForgetPasswordProps {
  isPublic?: boolean;
}

const ForgetPassword: React.FC<ForgetPasswordProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const resetToken = searchParams.get("reset_password_token");

  // Redirect if no token is present
  useEffect(() => {
    if (!resetToken) {
      toast.error("Invalid or missing reset token");
      navigate("/login");
    }
  }, [resetToken, navigate]);

  if (!resetToken) {
    return null;
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <div className="w-full max-w-md bg-white dark:bg-neutral-900 rounded-lg shadow-md p-8">
        <h2 className="text-2xl text-orange-500 text-center mb-6 dark:text-orange-400">
          Reset Password
        </h2>
        <Formik
          initialValues={{ password: "", confirmPassword: "" }}
          validationSchema={forgotValidationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              dispatch(
                createNewPassword({
                  token: resetToken,
                  payload: {
                    password: values.password,
                    confirm_password: values.confirmPassword,
                    message: "",
                  },
                })
              )
                .unwrap()
                .then((res) => {
                  if (res.success) {
                    toast.success(res.message || "Password reset successful!");
                    navigate("/login");
                  } else {
                    toast.error(res.message || "Password reset failed.");
                  }
                });
            } catch (error: any) {
              console.error("Failed to reset password:", error);
              const errorMessage =
                error.response?.data?.message ||
                "Failed to reset password. Please try again.";
              toast.error(errorMessage);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-orange-500 dark:text-orange-400"
                >
                  New Password
                </label>
                <Field
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter your new password"
                  className="w-full px-3 py-2 mt-1 border rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 dark:border-gray-600"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="confirmPassword"
                  className="block text-orange-500 dark:text-orange-400"
                >
                  Confirm Password
                </label>
                <Field
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Confirm your new password"
                  className="w-full px-3 py-2 mt-1 border rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 dark:border-gray-600"
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <button
                type="submit"
                className="w-full bg-orange-600 text-white py-2 px-4 rounded-md hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50 dark:bg-orange-500 dark:hover:bg-orange-600"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Resetting..." : "Reset Password"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgetPassword;
