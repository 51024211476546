import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { RootState, AppDispatch } from "redux/store";
import {
  getCurrentUser,
  updateUserAPI,
  updateUserEmailAsVerified,
  updateUserWithImageAPI,
} from "../../redux/reducers/UserSlice/UserSlice";
import successHandler from "../../utils/helpers/SuccessHandler";
import {
  socialMediaDetailsSchema,
  UpdateUserSchema,
} from "../../utils/formSchema";
import FormField from "../../shared/FormField/FormField";
import __countryListing from "../../data/jsons/__countryListing.json";
import Avatar from "shared/Avatar/Avatar";

const SocialMediaLink = ({ className = "" }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser, loading } = useSelector(
    (state: RootState) => state.user
  );
  const formik = useFormik({
    initialValues: {
      facebook_url: currentUser?.facebook_url || "",
      linkedin_url: currentUser?.linkedin_url || "",
      instagram_url: currentUser?.instagram_url || "",
    },
    validationSchema: socialMediaDetailsSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      dispatch(updateUserAPI({ user: values }))
        .unwrap()
        .then((response) => {
          if (response) {
            successHandler("Social media links updated successfully");
            dispatch(getCurrentUser());
          }
        })
        .catch((error: any) => {
          console.error("Error updating Social media links: ", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    // If the userData is loaded, update formik values
    if (currentUser) {
      formik.setValues({
        facebook_url: currentUser.facebook_url || "",
        linkedin_url: currentUser.linkedin_url || "",
        instagram_url: currentUser.instagram_url || "",
      });
    }
  }, [currentUser]);

  return (
    <div>
      <div className="flex flex-col md:flex-row">
        <div className="flex-shrink-0 flex items-start">
          <div className="relative rounded-full overflow-hidden flex">
            <Avatar
              sizeClass="w-32 h-32"
              containerClassName="hidden md:block invisible"
            />
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="flex-grow mt-10 md:mt-0 md:ml-8 space-y-6"
        >
          {/* Existing fields */}
          <h2 className="text-3xl font-semibold mt-6 mb-4">
            Social Media Links
          </h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mt-6 mb-6"></div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <FormField
              label="Facebook URL"
              name="facebook_url"
              placeholder="www.facebook.com"
              type="text"
              disabled={!currentUser?.email_verified}
              value={formik.values.facebook_url}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.facebook_url && formik.errors.facebook_url}
            />

            <FormField
              label="Instagram URL"
              name="instagram_url"
              placeholder="www.instagram.com"
              type="text"
              disabled={!currentUser?.email_verified}
              value={formik.values.instagram_url}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.instagram_url && formik.errors.instagram_url
              }
            />

            <FormField
              label="LinkedIn URL"
              name="linkedin_url"
              placeholder="www.linkedin.com"
              type="text"
              disabled={!currentUser?.email_verified}
              value={formik.values.linkedin_url}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.linkedin_url && formik.errors.linkedin_url}
            />
          </div>
          <div className="flex justify-end">
            <ButtonPrimary
              type="submit"
              // disabled={!currentUser?.email_verified}
              className="mt-5"
              loading={formik.isSubmitting}
              disabled={
                !currentUser?.email_verified || 
                (currentUser?.is_host && !currentUser?.host_verified)
              }
            >
              Save
            </ButtonPrimary>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SocialMediaLink;
