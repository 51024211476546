import React, { FC } from "react";

export interface SwitchButtonProps {
  label?: string;
  subLabel?: string;
  className?: string;
  name: string;
  isChecked: boolean;
  onChange?: (isChecked: boolean) => void;
}

const SwitchButton: FC<SwitchButtonProps> = ({
  subLabel = "",
  label = "",
  name,
  className = "",
  isChecked,
  onChange,
}) => {
  const handleToggle = () => {
    if (onChange) {
      onChange(!isChecked); 
    }
  };

  return (
    <div className={`flex items-center ${className}`}>
      {label && (
        <label
          htmlFor={name}
          className="ml-3.5 flex flex-col flex-1 justify-center cursor-pointer"
          onClick={handleToggle}
        >
          <span className="text-neutral-900 dark:text-neutral-100">
            {label}
          </span>
          {subLabel && (
            <p className="mt-1 text-neutral-500 dark:text-neutral-400 text-sm font-light">
              {subLabel}
            </p>
          )}
        </label>
      )}
      <div
        onClick={handleToggle}
        className={`relative inline-flex items-center h-6 w-11 cursor-pointer transition-colors duration-300 ease-in-out ${
          isChecked ? "bg-primary-500" : "bg-neutral-500"
        } rounded-full`}
      >
        <span
          className={`inline-block h-4 w-4 bg-white rounded-full shadow transform transition-transform duration-300 ease-in-out ${
            isChecked ? "translate-x-6" : "translate-x-1"
          }`}
        />
      </div>
    </div>
  );
};

export default SwitchButton;
