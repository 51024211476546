import React, { FC, useEffect, useState } from "react";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import {
  getallupdateProperties,
  updateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import { Formik, Form, Field } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import successHandler from "utils/helpers/SuccessHandler";
import { useSelector } from "react-redux";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { descriptionValidationSchema } from "utils/formSchema";

export interface PageAddListing7Props { }

const PageAddListing7: FC<PageAddListing7Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { globalId } = useSelector((state: RootState) => state.property);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [initialValues, setInitialValues] = useState<{ description: string }>({
    description: "",
  });

  const isEditing = Boolean(id || globalId);

  useEffect(() => {
    if (isEditing) {
      const fetchPropertyData = async () => {
        const propertyId = globalId || id;
        const response: any = await dispatch(
          getallupdateProperties({ propertyId })
        );

        const property = response.payload?.property;
        if (property) {
          setInitialValues({ description: property.description || "" });
        } else {
          console.error("Property not found:", property);
        }
      };

      fetchPropertyData();
    }
  }, [dispatch, globalId, id, isEditing]);

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    const payload = {
      property: {
        id: globalId || id,
        description: values.description,
      },
    };

    dispatch(updateProperties(payload))
      .unwrap()
      .then((response) => {
        if (response?.success) {
          successHandler("Property description updated successfully!");
          navigate(`/add-listing-8?id=${response.property.id}`);
        }
      })
      .catch((error) => {
        console.error("Error updating property description: ", error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <CommonLayout
      index="07"
      backtHref="/add-listing-6"
      nextHref="/add-listing-8"
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={descriptionValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <div>
              <h2 className="text-2xl font-semibold">
                Your place description for client
              </h2>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Mention the best features of your accommodation, any special
                amenities like fast Wi-Fi or parking, as well as things you like
                about the neighborhood.
              </span>
            </div>

            <Field
              name="description"
              as={Textarea}
              placeholder="A modern villa with stunning views of the sunset."
              rows={14}
              className={`${errors.description && touched.description
                ? "border-red-500"
                : ""
                } my-5`}
            />
            {errors.description && touched.description && (
              <div className="text-red-500 mt-2">{errors.description}</div>
            )}

            <div className="flex justify-end space-x-5 my-5">
              <ButtonSecondary href={`/add-listing-6?id=${globalId || id}`}>Go back</ButtonSecondary>
              <ButtonPrimary type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Loading..." : "Continue"}
              </ButtonPrimary>
            </div>
          </Form>
        )}
      </Formik>
    </CommonLayout>
  );
};

export default PageAddListing7;
