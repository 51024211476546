import { FC } from "react";
import { BookingRequestDetails } from "../../redux/reducers/Properties/PropertiesSliceTypes";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import {
    CreateOrderActions,
    CreateOrderData,
    OnApproveActions,
    OnApproveData,
} from "@paypal/paypal-js";

import { NavigateFunction, useNavigate } from "react-router-dom";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

interface InvoiceProps {
    bookingRequestDetails: BookingRequestDetails | null;
}

const Invoice: FC<InvoiceProps> = ({ bookingRequestDetails }) => {
    const navigate = useNavigate();

    const reserveBooking = useSelector(
        (state: RootState) => state.payment.reserveBooking
    );

    const onCreateOrder = (
        data: CreateOrderData,
        actions: CreateOrderActions
    ) => {
        if (reserveBooking?.booking?.total) { }
        return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
                {
                    amount: {
                        value: reserveBooking?.booking?.total ? reserveBooking?.booking?.total : '',
                        currency_code: "USD",
                    },
                },
            ],
            application_context: {
                return_url: `${window.location.origin}/success`, // Redirect back to this URL after payment
                cancel_url: `${window.location.origin}/booking`, // Optional: Redirect if payment is cancelled
            },
        });
    };

    const onApproveOrder = async (
        data: OnApproveData,
        actions: OnApproveActions,
        navigate: NavigateFunction
    ) => {
        if (actions.order) {
            const details = await actions.order.capture();
            if (details.status === "COMPLETED") {
                navigate("/success?payment_method=paypal&redirect_status=succeeded");
            }
        }
    };

    return (
        bookingRequestDetails && (
            <div className="flex p-6 bg-gray-100 justify-center mt-5  ">
                <div className="w-2/3 p-6 border rounded shadow bg-white">
                    <h2 className="text-xl font-medium mb-4">Create Invoice</h2>
                    <div className="mb-6">
                        <div className="flex flex-col space-y-2">
                            <p className="font-normal text-sm text-black">
                                <strong>Period :</strong>{" "}
                                <span className="font-normal text-gray-500">
                                    {bookingRequestDetails?.check_in_date} to{" "}
                                    {bookingRequestDetails?.check_out_date}
                                </span>
                            </p>
                            <p className="font-medium text-sm text-black">
                                <strong>No. of Months:</strong>{" "}
                                <span className="font-normal text-gray-500">1</span>
                            </p>
                            <p className="font-medium text-sm text-black">
                                <strong>No. of guests:</strong>{" "}
                                <span className="font-normal text-gray-500">
                                    {bookingRequestDetails?.number_of_guests}
                                </span>
                            </p>
                            <p className="font-medium text-sm text-black">
                                <strong>Price per Month:</strong>{" "}
                                <span className="font-normal text-gray-500">
                                    USD {bookingRequestDetails?.item_total}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div>
                        <table className="w-full mb-4 ">
                            <thead>
                                <tr className="border-b">
                                    <th className="text-left font-medium">Detail</th>
                                    <th className="text-left font-medium">Subtotal</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="border-b  text-gray-500 text-sm p-6">
                                    <td className="pt-4 pb-4">First month's rent</td>
                                    <td className="pt-4 pb-4">
                                        USD {bookingRequestDetails?.item_total}
                                    </td>
                                </tr>
                                <tr className="border-b  text-gray-500 text-sm p-6">
                                    <td className="pt-4 pb-4">Tax total</td>
                                    <td className="pt-4 pb-4">
                                        USD {bookingRequestDetails?.tax_total}
                                    </td>
                                </tr>
                                <tr className="border-b  text-gray-500 text-sm p-6">
                                    <td className="pt-4 pb-4">Invoice total</td>
                                    <td className="pt-4 pb-4">
                                        USD {bookingRequestDetails?.invoice?.total}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="flex justify-between font-medium">
                            <span>Total</span>
                            <span>USD {bookingRequestDetails?.total}</span>
                        </div>
                    </div>
                    <div className="flex gap-x-4 mt-6">
                        <button
                            onClick={() => navigate("/checkout")}
                            className="px-4 py-2 bg-blue-500 text-white rounded w-full  h-12"
                        >
                            Pay with Credit Card
                        </button>
                        {process.env.REACT_APP_PAYPAL_CLIENT_ID && (
                            <PayPalScriptProvider
                                options={{
                                    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                    currency: "USD",
                                    intent: "capture",
                                }}
                            >
                                <PayPalButtons
                                    style={{
                                        layout: "horizontal", // Horizontal layout for the button
                                        color: "blue", // Set color to match the theme of ButtonPrimary
                                        shape: "rect", // Rectangle shape similar to ButtonPrimary
                                        height: 48, // Matches your button's height
                                        tagline: false, // Hides the tagline
                                    }}
                                    className="w-full h-full"
                                    createOrder={(data, actions) => onCreateOrder(data, actions)}
                                    onApprove={(data, actions) => onApproveOrder(data, actions, navigate)}
                                />
                            </PayPalScriptProvider>
                        )}

                        {/* <div className="w-48 h-12 bg-white hover:bg-gray-100 rounded-lg transition duration-300 shadow-md transition duration-300">

            </div> */}
                    </div>
                </div>
            </div>
        )
    );
};

export default Invoice;
