import React, { FC, useEffect, useState } from "react";
import CheckboxV2 from "shared/Checkbox/CheckboxV2";
import CommonLayout from "./CommonLayout";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../redux/store";
import { Formik, Form } from "formik";
import {
  getallupdateProperties,
  getAmenitiesProperty,
  updateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface PageAddListing4Props { }

const PageAddListing4: FC<PageAddListing4Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [checkedItems, setCheckedItems] = useState<string[]>([]); // Holds selected IDs
  const navigate = useNavigate();
  const { globalId, amenitiesCategories } = useSelector(
    (state: RootState) => state.property
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const initialValues = {
    amenities: [], // Formik initial value
  };

  // Remove duplicates from amenities based on id
  const removeDuplicateAmenities = (amenitiesArray: any[]) => {
    return amenitiesArray.filter(
      (value, index, self) =>
        index === self.findIndex((amenity) => amenity.id === value.id)
    );
  };

  // Update the checkbox state based on user input
  const handleCheckboxChange = (isChecked: boolean, id?: string | number) => {
    const idString = id?.toString(); // Convert the id to a string to handle it uniformly
    if (isChecked && idString) {
      // Add the checked ID
      setCheckedItems((prev) => [...prev, idString]);
    } else if (!isChecked && idString) {
      // Remove unchecked ID
      setCheckedItems((prev) => prev.filter((item) => item !== idString));
    }
  };

  // Prepare and send the form data on submit
  const handleSubmit = (values: { amenities: any }) => {
    const updatedAmenities = checkedItems.map((item) => ({
      amenity_id: item,
    }));

    const payload = {
      id: globalId || id, // Make sure the correct ID is sent
      property_amenities_attributes: updatedAmenities, // Send updated amenities
    };

    dispatch(updateProperties({ property: payload }))
      .unwrap()
      .then((response) => {
        successHandler(response?.message || "Updated successfully");
        navigate(`/add-listing-5?id=${response.property.id}`);
      })
      .catch((error) => {
        console.error("Error updating amenities: ", error);
      });
  };

  // Fetch amenities categories for the form
  useEffect(() => {
    dispatch(getAmenitiesProperty())
      .unwrap()
      .then((response) => {
        // Log the amenitiesCategories response to the console
        console.log("Amenities Categories Response: ", response);
      })
      .catch((error) => {
        console.error("Error fetching amenities categories: ", error);
      });
  }, [dispatch]);

  // Load existing property data for editing (prefill form)
  useEffect(() => {
    const propertyId = globalId || id;
    if (propertyId) {
      dispatch(getallupdateProperties({ propertyId }))
        .unwrap()
        .then((response) => {
          // Prefill amenities, ensuring no duplicates
          const prefilledAmenities = removeDuplicateAmenities(
            response?.property?.amenities || []
          ).map((amenity: any) => amenity.id.toString());

          setCheckedItems(prefilledAmenities); // Set the checked items based on the prefilled data
        });
    }
  }, [dispatch, globalId, id]);

  return (
    <CommonLayout
      index="04"
      backtHref="/add-listing-3"
      nextHref="/add-listing-5"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Amenities</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Many customers have searched for accommodation based on amenities
            criteria
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <div className="space-y-8">
                {amenitiesCategories?.map((category) => (
                  <div key={category?.id}>
                    <label className="text-lg font-semibold">
                      {category?.name}
                    </label>
                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                      {/* Check if category.amenities exists and is not empty */}
                      {category?.amenities?.length > 0 ? (
                        category?.amenities?.map((amenity) => (
                          <CheckboxV2
                            id={amenity?.id}
                            key={amenity?.id}
                            label={amenity?.name}
                            name={amenity?.name}
                            onChange={handleCheckboxChange} // Pass handleCheckboxChange here
                            checked={checkedItems.includes(
                              amenity?.id.toString()
                            )} // Control checked state based on checkedItems array
                          />
                        ))
                      ) : (
                        <p className="text-neutral-500 dark:text-neutral-400">
                          No amenities available for this category.
                        </p>
                      )}
                    </div>
                  </div>
                ))}
                {errors.amenities && touched.amenities && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors.amenities}
                  </div>
                )}
              </div>
              <div className="flex justify-end space-x-5">
                <ButtonSecondary href={`/add-listing-3?id=${globalId || id}`}>Go back</ButtonSecondary>
                <ButtonPrimary type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Loading..." : "Continue"}
                </ButtonPrimary>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </CommonLayout>
  );
};

export default PageAddListing4;
