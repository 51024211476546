import { useEffect } from "react";
import successImage from "../../images/logos/payment_success.png";
import { useLocation } from "react-router-dom";
import {
  getPaymentMethods,
  paymentSuccess,
} from "../../redux/reducers/Payments/PaymentReducer";
import { AppDispatch, RootState } from "redux/store";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { PaymentMethod } from "redux/reducers/Payments/PaymentReducerTypes";
import successHandler from "utils/helpers/SuccessHandler";

const PaymentSuccess = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const query = new URLSearchParams(location.search);
  const status = query.get("redirect_status");
  const paymentMethod = query.get("payment_method") || "";
  const reserveBooking = useSelector(
    (state: RootState) => state.payment.reserveBooking
  );

  useEffect(() => {
    const confirmBooking = async () => {
      const paymentMethodResponse = await dispatch(getPaymentMethods());
      if (paymentMethodResponse?.payload?.success) {
        const payment = paymentMethodResponse?.payload?.data?.find(
          (item: PaymentMethod) => item.name?.toLowerCase() === paymentMethod
        );
        if (status) {
          const sucessPayload = {
            booking: {
              status: "confirmed",
              payments_attributes: [
                {
                  payment_method_id: payment?.id,
                  amount: reserveBooking?.booking?.total,
                  state: status === "succeeded" ? "paid" : "failed",
                },
              ],
            },
          };

          const action = await dispatch(paymentSuccess(sucessPayload as any));
          if (
            action?.type === "payment/paymentSuccess/fulfilled" &&
            action.payload
          ) {
            successHandler("Booking Confirm");
          }
        }
      }
    };
    confirmBooking();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-green-400 via-blue-500 to-purple-500 text-white px-4">
      <div className="bg-white text-center p-8 rounded-lg shadow-lg max-w-md w-full">
        <img
          src={successImage}
          alt="Payment Success"
          className="w-24 h-24 mx-auto mb-4"
        />

        <h1 className="text-3xl font-bold text-green-600 mb-4">
          Payment Successful!
        </h1>

        <p className="text-gray-700 mb-6">
          Thank you for your payment! Your transaction was processed
          successfully.
        </p>

        <div className="bg-gray-100 p-4 rounded-lg text-left mb-6">
          <h2 className="text-lg font-semibold text-gray-900">Order Summary</h2>
          <p className="text-gray-600">
            Order ID:{" "}
            <span className="font-semibold">#{reserveBooking?.booking?.number}</span>
          </p>
          <p className="text-gray-600">
            Amount:{" "}
            <span className="font-semibold">${reserveBooking?.booking?.total}</span>
          </p>
        </div>

        <button
          onClick={() => (window.location.href = "/home-1-header-2")} // Adjust redirect as needed
          className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-6 rounded-lg shadow-lg transition-all duration-300"
        >
          Go to Dashboard
        </button>
      </div>

      <p className="text-gray-200 mt-8">
        Need help?{" "}
        <a href="/support" className="text-green-300 underline">
          Contact Support
        </a>
      </p>
    </div>
  );
};

export default PaymentSuccess;
