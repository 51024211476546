import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  updateProperties,
  getallupdateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { PricevalidationSchema } from "utils/formSchema";


export interface PageAddListing9Props { }

const PageAddListing9: FC<PageAddListing9Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { globalId } = useSelector((state: RootState) => state.property);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  // State to handle the initial form values
  const [initialValues, setInitialValues] = useState({
    pricePerNight: 0, // Default value for new properties
  });

  // Fetch property data if we are editing (when globalId exists)
  useEffect(() => {
    if (globalId || id) {
      const fetchPropertyData = async () => {
        const response: any = await dispatch(
          getallupdateProperties({ propertyId: globalId || id })
        );

        // If the property exists, update the form's initial values with the data
        const property = response.payload?.property;
        if (property) {
          setInitialValues({
            pricePerNight: property.price_per_night || 0, // Prefill price from the response
          });
        } else {
          console.error("Property not found:", property);
        }
      };

      fetchPropertyData();
    }
  }, [dispatch, globalId]);

  const formik = useFormik({
    enableReinitialize: true, // This allows Formik to reset the form when initialValues change
    initialValues: initialValues, // Initial values come from state (either default or fetched)
    validationSchema: PricevalidationSchema, // Use Yup validation schema
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const payload = {
        id: globalId || id,
        price_per_night: values.pricePerNight,
      };

      setSubmitting(true);
      dispatch(updateProperties({ property: payload }))
        .unwrap()
        .then((response) => {
          if (response) {
            successHandler(response?.message || "Updated successfully");
            resetForm();
            navigate(`/add-listing-10?id=${response.property.id}`);
          }
        })
        .catch((error) => {
          console.error("Error updating property: ", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <CommonLayout
      index="09"
      backtHref="/add-listing-8"
      nextHref="/add-listing-10"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Price your space</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            The host's revenue is directly dependent on the setting of rates and
            regulations on the number of guests, the number of nights, and the
            cancellation policy.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* FORM */}
        <form onSubmit={formik.handleSubmit}>
          <div className="space-y-8">
            <FormItem label="Price per month">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500">$</span>
                </div>
                <Input
                  name="pricePerNight"
                  type="number"
                  placeholder="0.00"
                  className="!pl-8 !pr-10"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pricePerNight}
                />
                {formik.errors.pricePerNight &&
                  formik.touched.pricePerNight && (
                    <div className="text-red-600">
                      {formik.errors.pricePerNight}
                    </div>
                  )}
              </div>
            </FormItem>
          </div>

          {/* Submit and Navigation Buttons */}
          <div className="flex justify-end space-x-5 mt-8">
            <ButtonSecondary href={`/add-listing-8?id=${globalId || id}`}>Go back</ButtonSecondary>
            <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
                { formik.isSubmitting ? "Loading..." : "Continue"}
              </ButtonPrimary>
          </div>
        </form>
      </>
    </CommonLayout>
  );
};

export default PageAddListing9;
