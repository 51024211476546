import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { RootState, AppDispatch } from "redux/store";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { createChat, bookingQueries} from "../../../redux/reducers/UserSlice/UserSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Room,booking} from "redux/reducers/UserSlice/UserSliceType";
import { toast} from 'react-toastify';
import { getallupdateProperties } from "../../../redux/reducers/Properties/PropertiesSlice";

interface ContactOwnerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSend: (data:booking) => void;
  propertyData?: any;
}

const ContactOwnerModal: React.FC<ContactOwnerModalProps> = ({
  isOpen,
  onClose,
  onSend,
  propertyData={}
}) => {
  const property = useSelector((state: RootState) => state.property);
  const [available, setAvailable] = useState<Room[]>([]);
  const [showRoomNumber, setShowRoomNumber] = useState(true);
  const { currentUser, loading } = useSelector(
    (state: RootState) => state.user
  );
  
  const router = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { globalId } = useSelector((state: RootState) => state.property);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const  propertyId = globalId || id 
  useEffect(() => {
    const fetchPropertyType = async () => {
      try {
        const result = await dispatch(getallupdateProperties({propertyId})).unwrap();

        if (result.success && result.property) {
          const isSharedProperty = result.property.is_shared_property;

          if (isSharedProperty) {
            setShowRoomNumber(true);
          } else {
            setShowRoomNumber(false);
          }
        } else {
          console.error("No property found in the response");
        }
      } catch (error) {
        console.error("Failed to fetch property type:", error);
      }
    };

    fetchPropertyType();
  }, [dispatch]);

  const handleChatButtonClick = async () => {
    try {
      await dispatch(
        createChat({ sender_id: currentUser?.id, receiver_id: propertyData?.user?.id, property_id: propertyData?.id })
      ).unwrap();
      toast.success("Message sent successfully!");
      //router("/messages");
    } catch (error) {
      alert("Failed to create chat. Please try again.");
    }
  }; 

  const validationSchema = Yup.object({
    check_in_date: Yup.date().required("Check In date is required"),
    check_out_date: Yup.date().required("Check Out date is required"),
    guest_count: Yup.number()
      .min(1, "At least one guest is required")
      .required("Number of guests is required"),
    query_for: Yup.string().required("Please select a room"),
    query: Yup.string().max(500, "Message must be 500 characters or less"),
  });

  const formik = useFormik({
    initialValues: {
      check_in_date: "",
      check_out_date: "",
      guest_count: 1,
      query_for: "",
      query: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      const queryFor = values.query_for
        ? { room_number: [values.query_for] }
        : {};

      onSend(values);

      try {
        await dispatch(
          bookingQueries({
            ...values,
            query_for: queryFor,
            property_id: propertyId,
          })
        );
      } catch (error) {
        console.error("Failed to dispatch booking query:", error);
      }

      onClose();
     },
});

  const handleRoomsClick = async () => {
    try {
      const result = await dispatch(getallupdateProperties({ propertyId })).unwrap();
      console.log("Available rooms:", result);
      if (
        result.success &&
        result.property &&
        Array.isArray(result.property.rooms)
      ) {
        const rooms: Room[] = result.property.rooms.map((room: any) => ({
          id: room.id,
          name: room.room_type.name,
        }));
        setAvailable(rooms);
      } else {
        setAvailable([]);
      }
    } catch (error) {
      console.error("Error fetching available rooms:", error);
      setAvailable([]);
    }
  };
 
  

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
      <div className="enquiry-modal bg-white rounded-lg shadow-lg p-6 w-full max-w-md relative mt-16 z-50 mr-7">
        <button
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 focus:outline-none"
          onClick={onClose}
        >
          ×
        </button>
        <h2 className="text-xl font-semibold text-gray-800 mb-2 text-center">
          Contact the owner
        </h2>
        <p className="text-gray-500 text-center mb-4">
          Please complete the form below to contact the owner.
        </p>

        <form onSubmit={formik.handleSubmit}>
          <label className="block">
            <span className="text-gray-700">Check In</span>
            <input
              type="date"
              name="check_in_date"
              value={formik.values.check_in_date}
              onChange={formik.handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </label>

          <label className="block">
            <span className="text-gray-700">Check Out</span>
            <input
              type="date"
              name="check_out_date"
              value={formik.values.check_out_date}
              onChange={formik.handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </label>

          <label className="block">
            <span className="text-gray-700">Guests</span>
            <select
              name="guest_count"
              value={formik.values.guest_count}
              onChange={formik.handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              {Array.from({ length: 10 }, (_, n) => (
                <option key={n} value={n + 1}>
                  {n + 1} Guest
                </option>
              ))}
            </select>
            {formik.touched.guest_count && formik.errors.guest_count && (
              <div className="text-red-500 text-sm">
                {formik.errors.guest_count}
              </div>
            )}
          </label>

          {showRoomNumber && (
            <label className="block">
              <span className="text-gray-700">Room-Number</span>
              <select
                name="query_for"
                value={formik.values.query_for}
                onChange={formik.handleChange}
                onClick={handleRoomsClick}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option value="">Select number of rooms</option>
                {available.length > 0 ? (
                  available.map((room) => (
                    <option key={room.id} value={room.id}>
                      {room.name} - {room.id}
                    </option>
                  ))
                ) : (
                  <option disabled>No rooms available</option>
                )}
              </select>
              {formik.touched.query_for && formik.errors.query_for && (
                <div className="text-red-500 text-sm">
                  {formik.errors.query_for}
                </div>
              )}
            </label>
          )}

          <label className="block">
            <span className="text-gray-700">Query</span>
            <textarea
              name="query"
              value={formik.values.query}
              onChange={formik.handleChange}
              placeholder="Your message"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            {formik.touched.query && formik.errors.query && (
              <div className="text-red-500 text-sm">{formik.errors.query}</div>
            )}
          </label>

          <button
            type="submit"
            onClick={handleChatButtonClick}
            className="w-full py-2 bg-orange-500 text-white font-semibold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 mt-4"
          >
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactOwnerModal;
