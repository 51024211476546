import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import {
  getallupdateProperties,
  getHouseRule,
  updateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import successHandler from "utils/helpers/SuccessHandler";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { createHouseRulesValidationSchema } from "utils/formSchema";

export interface PageAddListing6Props { }

const PageAddListing6: FC<PageAddListing6Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { globalId, houseRule, PropertyToEdit } = useSelector(
    (state: RootState) => state.property
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [additionalRules, setAdditionalRules] = useState<string[]>([]);
  const [newRuleInput, setNewRuleInput] = useState("");

  useEffect(() => {
    dispatch(getHouseRule());
  }, [dispatch]);

  useEffect(() => {
    const propertyId = globalId || id;
    if (propertyId) {
      dispatch(getallupdateProperties({ propertyId }))
        .unwrap()
        .then((response) => {
          // Set additional rules, ensuring no duplicates
          const existingAdditionalRules: string[] = Array.from(
            new Set(
              response?.property?.additional_rules?.map((rule: any) => rule.name) || []
            )
          );

          // Reset additional rules to avoid duplication
          setAdditionalRules(existingAdditionalRules);
        });
    }
  }, [globalId, id]);

  // Prepare initial values based on PropertyToEdit data
  const initialValues = PropertyToEdit?.house_rules
    ? PropertyToEdit.house_rules.reduce((acc: any, rule: any) => {
      acc[rule.id] = rule.value;
      return acc;
    }, {})
    : {};

  // Handle adding a new rule without duplication
  const handleAddRule = () => {
    const trimmedRule = newRuleInput.trim();
    if (trimmedRule !== "" && !additionalRules.includes(trimmedRule)) {
      setAdditionalRules((prev) => [...prev, trimmedRule]);
      setNewRuleInput("");
    }
  };

  const validationSchema = createHouseRulesValidationSchema(houseRule);

  const handleSubmit = (values: any) => {
    const property_house_rules_attributes = Object.entries(values).map(([id, value]: any) => ({
      house_rule_id: Number(id),
      value: value,
    }));

    const additional_rules_attributes = additionalRules.map((rule) => ({
      name: rule,
    }));

    const payload = {
      id: globalId || id,
      property_house_rules_attributes,
      additional_rules_attributes,
    };

    dispatch(updateProperties({ property: payload }))
      .unwrap()
      .then((response) => {
        if (response) {
          successHandler(response?.message || "Updated successfully");
          navigate(`/add-listing-7?id=${response.property.id}`);
        }
      })
      .catch((error) => {
        console.error("Error updating house rules: ", error);
      });
  };

  const handleRemoveRule = (index: number) => {
    setAdditionalRules((prevRules) => prevRules.filter((_, i) => i !== index));
  };

  const renderRadio = (name: string, value: string, label: string) => (
    <div className="flex items-center">
      <Field
        name={name}
        type="radio"
        value={value}
        className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
      />
      <label
        htmlFor={value + name}
        className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
      >
        {label}
      </label>
    </div>
  );

  const renderNoInclude = (text: string, index: number) => (
    <div className="flex items-center justify-between py-3">
      <span className="text-neutral-600 dark:text-neutral-400 font-medium">
        {text}
      </span>
      <button
        type="button"
        onClick={() => handleRemoveRule(index)}
        className="text-2xl text-neutral-400 hover:text-neutral-900 dark:hover:text-neutral-100"
      >
        <i className="las la-times-circle" />
      </button>
    </div>
  );

  return (
    <CommonLayout index="06" backtHref="/add-listing-4" nextHref="/add-listing-6">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form>
            <div>
              <h2 className="text-2xl font-semibold">
                Set house rules for your guests
              </h2>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Guests must agree to your house rules before they book.
              </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

            <div className="space-y-8">
              {houseRule.map((item: any) => (
                <div key={item.id}>
                  <label className="text-lg font-semibold" htmlFor={item.id}>
                    {item.name}
                  </label>
                  <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                    {["Allow", "Charge", "Disallow"].map((option) => (
                      renderRadio(item.id.toString(), option, option)
                    ))}
                  </div>
                  {touched[item.id] && errors[item.id] && (
                    <div className="text-red-500 text-sm mt-1">
                      <ErrorMessage name={item.id} />
                    </div>
                  )}
                </div>
              ))}

              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <span className="block text-lg font-semibold">Additional rules</span>
              <div className="flow-root">
                <div className="-my-3 divide-y divide-neutral-100 dark:divide-neutral-800">
                  {additionalRules.map((rule, index) => renderNoInclude(rule, index))}
                </div>
              </div>
              <div className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
                <Input
                  className="!h-full"
                  placeholder="No smoking..."
                  value={newRuleInput}
                  onChange={(e) => setNewRuleInput(e.target.value)}
                />
                <ButtonPrimary type="button" className="flex-shrink-0" onClick={handleAddRule}>
                  <i className="text-xl las la-plus"></i>
                  <span className="ml-3">Add</span>
                </ButtonPrimary>
              </div>

              <div className="flex justify-end space-x-5">
                <ButtonSecondary href={`/add-listing-5?id=${globalId || id}`}>Go back</ButtonSecondary>
                <ButtonPrimary type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Loading..." : "Continue"}
                </ButtonPrimary>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CommonLayout>
  );
};

export default PageAddListing6;

