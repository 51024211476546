import React from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "./PhoneNumberInput.scss";

const PhoneNumberInput = ({ value, onChange, label='', disabled=false }: any) => {
  return (
    <>
      <label className="block mb-1">{label}</label>
      <PhoneInput
        value={value.phone}
        disabled={disabled}
        className="react-country-phone-number-input-box"
        inputClassName="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white !dark:border-neutral-700 !dark:focus:ring-primary-6000 !dark:focus:ring-opacity-25 !dark:bg-neutral-900 !h-11 px-4 !py-3 text-sm font-normal !rounded-2xl"
        onChange={(value, meta) => {
          const country_code = meta?.country?.dialCode || "";
          onChange({
            phone: value,
            country_code,
          });
        }}
      />
    </>
  );
};

export default PhoneNumberInput;
