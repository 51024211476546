import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SwitchButton from "shared/SwitchButton/SwitchButton";
import { getCurrentUser, updateNotificationPreferences } from "../../redux/reducers/UserSlice/UserSlice"; // Import your action
import { AppDispatch, RootState } from "../../redux/store";
import successHandler from "../../utils/helpers/SuccessHandler";

const Notifications = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser, loading } = useSelector(
    (state: RootState) => state.user
  );

  const [notificationPreferences, setNotificationPreferences] = useState({
    whatsapp_notification: false,
    sms_notification: false,
  });

  useEffect(()=>{
    setNotificationPreferences({
      whatsapp_notification: currentUser?.whatsapp_notification || false,
      sms_notification: currentUser?.sms_notification || false,
    });
  },[currentUser])

  const [isLoading, setIsLoading] = useState(false);

  const handleToggle = (name: string) => {
    setNotificationPreferences((prevState) => ({
      ...prevState,
      [name]: !prevState[name as keyof typeof prevState],
    }));
  };

  const handleSubmit = () => {
    setIsLoading(true)
    dispatch(updateNotificationPreferences(notificationPreferences))
      .unwrap()
      .then(() => {
        successHandler("Notification preferences updated successfully.");
        dispatch(getCurrentUser());
        setIsLoading(false)
      })
      .catch((error) => {
        console.error("Error updating preferences:", error);
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      });
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex-shrink-0 flex items-start">
        <div className="relative rounded-full overflow-hidden flex">
          <Avatar
            sizeClass="w-32 h-32"
            containerClassName="hidden md:block invisible"
          />
        </div>
      </div>
      <div className="flex-grow mt-10 md:mt-0 md:ml-8 space-y-6">
        <h2 className="text-3xl font-semibold mt-6 mb-4">Notifications</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mt-6 mb-6"></div>

        {/* SMS Notifications */}
        <div className="">
          <SwitchButton
            name="sms_notification"
            label=" * SMS Notifications"
            subLabel="Receive booking requests by SMS on your verified phone number."
            isChecked={notificationPreferences.sms_notification} // Controlled via state
            onChange={() => handleToggle("sms_notification")}
          />
        </div>

        {/* WhatsApp Notifications */}
        {!currentUser?.is_host && (
        <SwitchButton
          name="whatsapp_notification"
          label=" * WhatsApp Notifications"
          subLabel="We send by default all the bookings and automated notifications by email."
          isChecked={notificationPreferences.whatsapp_notification} // Controlled via state
          onChange={() => handleToggle("whatsapp_notification")}
          className="mt-6"
        />
        )}

        {/* Save Button */}
        <div className="flex justify-end">
          <ButtonPrimary
            type="button"
            onClick={handleSubmit}
            className="mt-5"
            disabled={
              !currentUser?.email_verified || 
              (currentUser?.is_host && !currentUser?.host_verified)
            }
            loading={isLoading}
          >
            Save
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
