import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik, FormikProvider, FieldArray } from "formik";
import { AppDispatch, RootState } from "redux/store";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import successHandler from "utils/helpers/SuccessHandler";
import { getGetTax, updateProperties, getallupdateProperties } from "../../redux/reducers/Properties/PropertiesSlice";
import { FormValues, GetTaxResponse, PropertyTaxResponse, TaxOption, UpdatePropertyResponse } from "redux/reducers/Properties/PropertiesSliceTypes";

export interface PageAddListing12Props { }

const PageAddListing12: FC<PageAddListing12Props> = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { globalId } = useSelector((state: RootState) => state.property);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const propertyId = globalId || id;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [taxOptions, setTaxOptions] = useState<TaxOption[]>([]);
    const [initialValues, setInitialValues] = useState<FormValues>({
        property_taxes_attributes: [],
    });

    useEffect(() => {
        const fetchTaxDetails = async (): Promise<void> => {
            setIsLoading(true);
            setError(null);

            try {
                // Fetch available tax types
                const taxResponse = await dispatch(getGetTax()).unwrap() as GetTaxResponse;
                if (taxResponse?.success) {
                    setTaxOptions(taxResponse.tax);
                } else {
                    throw new Error("Failed to fetch tax types");
                }
                if (propertyId) {
                    const propertyResponse = await dispatch(
                        getallupdateProperties({ propertyId })
                    ).unwrap() as PropertyTaxResponse;

                    if (propertyResponse?.property?.property_taxes?.length > 0) {
                        setInitialValues({
                            property_taxes_attributes: propertyResponse.property.property_taxes.map(tax => ({
                                tax_id: tax.tax_id,
                                value: tax.value || "",
                            })),
                        });
                    } else {
                        // Populate initial values with empty fields if no tax data exists
                        setInitialValues({
                            property_taxes_attributes: taxResponse.tax.map(tax => ({
                                tax_id: tax.id,
                                value: "",
                            })),
                        });
                    }
                }
            } catch (error) {
                const errorMessage = error instanceof Error ? error.message : "Failed to fetch tax details";
                setError(errorMessage);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTaxDetails();
    }, [dispatch, propertyId]);

    const formik = useFormik<FormValues>({
        enableReinitialize: true,
        initialValues,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const payload = {
                    property: {
                        id: propertyId,
                        property_taxes_attributes: values.property_taxes_attributes?.map((x, idx) => {
                            if (x.value) {
                                return {
                                    // tax_id: idx === 0 ? 1 : idx === 1 ? 3 : 2,
                                    value: Number(x.value),
                                    tax_id: x.tax_id
                                }
                            }
                        })

                    },
                };
                console.log("payload>>>>>>", payload)
                const response = await dispatch(updateProperties(payload)).unwrap() as UpdatePropertyResponse;

                if (response?.property?.id) {
                    successHandler(response?.message || "Tax updated successfully");
                    navigate(`/add-listing-13?id=${response.property.id}`);
                } else {
                    throw new Error("Failed to update tax");
                }
            } catch (error) {
                const errorMessage = error instanceof Error ? error.message : "Failed to update tax";
                setError(errorMessage);
            } finally {
                setSubmitting(false);
            }
        },
    });

    if (isLoading) {
        return (
            <CommonLayout index="12" nextHref="/add-listing-13" backtHref="/add-listing-11">
                <div className="flex items-center justify-center py-10">
                    <div className="text-neutral-500">Loading tax details...</div>
                </div>
            </CommonLayout>
        );
    }

    return (
        <CommonLayout index="12" nextHref="/add-listing-13" backtHref="/add-listing-11">
            <div className="space-y-8">
                <div>
                    <h2 className="text-2xl font-semibold">Tax Details</h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                        Specify tax details for this listing.
                    </span>
                </div>

                {error && (
                    <div className="p-4 text-red-500 bg-red-50 rounded-lg">
                        {error}
                    </div>
                )}

                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit} className="space-y-8">
                        <FieldArray name="property_taxes_attributes">
                            {() => (
                                <div className="space-y-6">
                                    {taxOptions.map((taxOption, index) => (
                                        <div key={taxOption.id} className="flex justify-between items-center space-x-4">
                                            <span className="text-lg font-medium">{taxOption.name}</span>
                                            <Input
                                                type="text"
                                                name={`property_taxes_attributes.${index}.value`}
                                                placeholder="Enter tax rate"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.property_taxes_attributes[index]?.value || ""}
                                                className="w-1/3 border rounded-lg p-2"
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </FieldArray>

                        <div className="flex justify-end space-x-5">
                            <ButtonSecondary href={`/add-listing-11?id=${propertyId || id}`}>
                                Go back
                            </ButtonSecondary>
                            <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
                                {formik.isSubmitting ? "Loading..." : "Continue"}
                            </ButtonPrimary>
                        </div>
                    </form>
                </FormikProvider>
            </div>
        </CommonLayout>
    );
};

export default PageAddListing12;
