import React from "react";
import { Navigate } from "react-router-dom";

interface AuthWrapperProps {
  children: React.ReactNode;
}

const isAuthenticated = () => {
  // Replace this with your actual authentication logic
  return localStorage.getItem("authToken") !== null;
};

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  if (!isAuthenticated()) {
    // Redirect to login page if the user is not authenticated
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
};

export default AuthWrapper;
