import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import API from "../../../utils/helpers/APIHelper";
import { RootState } from "../../store";
import ErrorHandler from "../../../utils/helpers/ErrorHandler";
import { ENDPOINTS } from "../../../API/apiConstants";
import { Chat, ChatListsResponse } from "./ChatSliceTypes";

type PaymentInitialState = {
  loading: boolean;
  chatLists: ChatListsResponse;
};

const initialState: PaymentInitialState = {
  loading: false,
  chatLists: {
    message: "",
    chats: [],
    success: false,
  },
};

export const getHostChatLists = createAsyncThunk<
  ChatListsResponse,
  void,
  { state: RootState; rejectValue: any }
>("chat/getHostChatLists", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.HOST_CHAT_LISTS);
    const data = response.data;

    const formattedData = {
      chats: data.chats?.map((chat: Chat) => {
        const {
          id,
          last_message,
          last_message_time,
          property,
          receiver,
          sender,
          receiver_id,
          sender_id,
          unread_count,
        } = chat;
        return {
          id,
          property: {
            id: property.id,
            address: property.address,
            availabilityEnd: property.availability_end,
            availabilityStart: property.availability_start,
            city: property.city,
            country: property.country,
            extraGuest: property.extra_guest,
            latitude: property.latitude,
            longitude: property.longitude,
            placeImages: property.place_images?.map((item) => item),
            pricePerNight: property.price_per_night,
            state: property.state,
            title: property.title,
            totalRooms: property.total_rooms,
          },
          lastMessage: last_message,
          lastMessageTime: last_message_time,
          receiver: {
            id: receiver.id,
            name: receiver.name,
            image: receiver.image,
          },
          sender: {
            id: sender.id,
            name: sender.name,
            image: sender.image,
          },
          receiverId: receiver_id,
          senderId: sender_id,
          unreadCount: unread_count,
        };
      }),
      message: data.message,
      success: data.success,
    };

    return formattedData;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getChatLists = createAsyncThunk<
  ChatListsResponse,
  void,
  { state: RootState; rejectValue: any }
>("chat/getStudentChatLists", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.STUDENT_CHAT_LISTS);
    const data = response.data;

    const formattedData = {
      chats: data.chats?.map((chat: Chat) => {
        const {
          id,
          last_message,
          last_message_time,
          property,
          receiver,
          sender,
          receiver_id,
          sender_id,
          unread_count,
        } = chat;
        return {
          id,
          property: {
            id: property.id,
            address: property.address,
            availabilityEnd: property.availability_end,
            availabilityStart: property.availability_start,
            city: property.city,
            country: property.country,
            extraGuest: property.extra_guest,
            latitude: property.latitude,
            longitude: property.longitude,
            placeImages: property.place_images?.map((item) => item),
            pricePerNight: property.price_per_night,
            state: property.state,
            title: property.title,
            totalRooms: property.total_rooms,
          },
          lastMessage: last_message,
          lastMessageTime: last_message_time,
          receiver: {
            id: receiver.id,
            name: receiver.name,
            image: receiver.image,
          },
          sender: {
            id: sender.id,
            name: sender.name,
            image: sender.image,
          },
          receiverId: receiver_id,
          senderId: sender_id,
          unreadCount: unread_count,
        };
      }),
      message: data.message,
      success: data.success,
    };

    return formattedData;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(getHostChatLists.fulfilled, getChatLists.fulfilled),
      (state, action) => {
        state.loading = false;
        state.chatLists = action.payload;
      }
    );
    builder.addMatcher(
      (action) => [getHostChatLists.pending.type].includes(action.type),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      (action) => [getHostChatLists.rejected.type].includes(action.type),
      (state) => {
        state.loading = false;
      }
    );
  },
});

export const {} = chatSlice.actions;

export default chatSlice.reducer;
