import NcInputNumberV2 from "components/NcInputNumber/NcInputNumberV2";
import React, { FC, useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "redux/store";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  getallupdateProperties,
  updateProperties,
} from "../../redux/reducers/Properties/PropertiesSlice";
import successHandler from "utils/helpers/SuccessHandler";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface PageAddListing10Props { }

const PageAddListing10: FC<PageAddListing10Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { globalId } = useSelector((state: RootState) => state.property);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [availabilityStart, setAvailabilityStart] = useState<string>("");
  const [availabilityEnd, setAvailabilityEnd] = useState<string>("");

  const formik = useFormik({
    initialValues: {
      minimumDaysOfBooking: 0,
    },
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const payload: any = {
        id: globalId || id,
        availability_start: availabilityStart,
        availability_end: availabilityEnd,
        minimum_days_of_booking: values.minimumDaysOfBooking,
      };

      setSubmitting(true);
      dispatch(updateProperties({ property: payload }))
        .unwrap()
        .then((response: any) => {
          if (response) {
            successHandler(response?.message || "Updated successfully");
            resetForm();
            navigate(`/add-listing-11?id=${response.property.id}`);
          }
        })
        .catch((error: any) => {
          console.error("Error updating property: ", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    const propertyId = globalId || id;
    if (propertyId) {
      dispatch(getallupdateProperties({ propertyId }))
        .unwrap()
        .then((response: any) => {
          if (response.success && response.property) {
            const {
              availability_start,
              availability_end,
              minimum_days_of_booking,
            } = response.property;

            setAvailabilityStart(availability_start || "");
            setAvailabilityEnd(availability_end || "");

            formik.setFieldValue(
              "minimumDaysOfBooking",
              minimum_days_of_booking || 0
            );
          }
        })
        .catch((error: any) => {
          console.error("Error fetching property: ", error);
        });
    }
  }, [dispatch, globalId, id]);

  return (
    <CommonLayout
      index="10"
      backtHref="/add-listing-9"
      nextHref="/add-listing-11"
    >
      <div>
        <h2 className="text-2xl font-semibold">How long can guests stay?</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Shorter trips can mean more reservations, but you'll turn over your
          space more often.
        </span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

      {/* FORM */}
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-7">
          {/* Minimum Days of Booking */}
          <NcInputNumberV2
            label="Month"
            value={formik.values.minimumDaysOfBooking}
            onChange={(value: number) =>
              formik.setFieldValue("minimumDaysOfBooking", value)
            }
          />

          {/* Availability Dates */}
          <div>
            <h2 className="text-2xl font-semibold">Set your availability</h2>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Select start and end dates to set availability, or type custom
              dates.
            </span>

            <div className="grid grid-cols-2 gap-4 mt-5">
              {/* Start Date Input */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-5">
                  Start Date
                </label>
                <input
                  type="date"
                  className="w-full border rounded-lg p-2"
                  value={availabilityStart}
                  onChange={(e) => setAvailabilityStart(e.target.value)}
                  placeholder="Select Start Date"
                />
              </div>

              {/* End Date Input */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-5">
                  End Date
                </label>
                <input
                  type="date"
                  className="w-full border rounded-lg p-2"
                  value={availabilityEnd}
                  onChange={(e) => setAvailabilityEnd(e.target.value)}
                  placeholder="Select End Date"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end space-x-5 mt-8">
          <ButtonSecondary href={`/add-listing-9?id=${globalId || id}`}>Go back</ButtonSecondary>
          <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? "Loading..." : "Continue"}
          </ButtonPrimary>
        </div>
      </form>
    </CommonLayout>
  );
};

export default PageAddListing10;
