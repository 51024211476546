import {
  addCreditCard,
  getCurrentUser,
} from "../../redux/reducers/UserSlice/UserSlice";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { RootState, AppDispatch } from "redux/store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  appPayPal,
  editPayPal,
} from "../../redux/reducers/UserSlice/UserSlice";
import { toast } from "react-toastify";
import successHandler from "utils/helpers/SuccessHandler";
import ErrorHandler from "utils/helpers/ErrorHandler";
import {
  creditCardValidationSchema,
  paypalValidationSchema,
} from "../../utils/formSchema";

const PaymentInfo = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser } = useSelector((state: RootState) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaypalModalOpen, setIsPaypalModalOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: currentUser?.credit_cards?.name || "",
      cc_number: currentUser?.credit_cards?.cc_number || "",
      month: currentUser?.credit_cards?.month || "",
      year: currentUser?.credit_cards?.year || "",
    },
    validationSchema: creditCardValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(addCreditCard(values))
        .unwrap()
        .then(() => {
          successHandler("Credit card details updated successfully!");
          setSubmitting(false);
          dispatch(getCurrentUser());
          closeModal();
        })
        .catch(() => {
          ErrorHandler("Error updating credit card details");
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    if (currentUser) {
      formik.setValues({
        name: currentUser?.credit_cards?.name || "",
        cc_number: currentUser?.credit_cards?.cc_number || "",
        month: currentUser?.credit_cards?.month || "",
        year: currentUser?.credit_cards?.year || "",
      });
    }
  }, [currentUser, isModalOpen]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openPaypalModal = () => {
    setIsPaypalModalOpen(true);
  };

  const closePaypalModal = () => {
    setIsPaypalModalOpen(false);
  };

  const handlePayPalSubmit = (
    values: { email: string },
    setSubmitting: (isSubmitting: boolean) => void,
    dispatch: AppDispatch,
    paypalId: number | null,
    closePaypalModal: () => void
  ) => {
    setSubmitting(true);

    if (paypalId) {
      dispatch(
        editPayPal({
          id: paypalId,
          user_paypal: { email: values.email },
        })
      )
        .unwrap()
        .then((result) => {
          if (result?.success) {
            toast.success("PayPal email updated successfully!");
          } else {
            toast.error("Failed to update PayPal email.");
          }
          closePaypalModal();
        })
        .catch(() => {
          toast.error("Failed to process PayPal email.");
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      dispatch(
        appPayPal({
          user_paypal: { email: values.email },
        })
      )
        .unwrap()
        .then((result) => {
          if (result?.success) {
            toast.success("PayPal email added successfully!");
          } else {
            toast.error("Failed to add PayPal email.");
          }
          closePaypalModal();
        })
        .catch(() => {
          toast.error("Failed to process PayPal email.");
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <div className="flex-shrink-0 flex items-start">
          <div className="relative rounded-full overflow-hidden flex">
            <Avatar
              sizeClass="w-32 h-32"
              containerClassName="hidden md:block invisible"
            />
          </div>
        </div>
        <div className="flex-grow mt-10 md:mt-0 md:ml-8 space-y-6">
          <h2 className="text-3xl font-semibold mt-6 mb-4">Payment Info</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mt-6 mb-6"></div>
          <div>
            <h2 className="text-lg font-semibold mb-2">Payment</h2>

            <p className="text-sm text-gray-600 mb-4">
              Choose your payment method
            </p>

            <div className="flex space-x-4">
              <ButtonPrimary onClick={openModal} className="mt-4 mb-6">
                Credit Card
              </ButtonPrimary>

              <ButtonPrimary onClick={openPaypalModal} className="mt-4 mb-6">
                PayPal
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75">
          <div className="bg-white dark:bg-gray-900 p-6 rounded-lg shadow-lg w-11/12 max-w-md">
            <h2 className="text-2xl font-semibold mb-4">Add Credit Card</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium mb-2"
                  htmlFor="cardName"
                >
                  Name on Card
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="John Doe"
                  className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-red-600 text-sm mt-1">
                    {formik.errors.name}
                  </div>
                )}
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium mb-2"
                  htmlFor="cardLastDigits"
                >
                  Last 4 Digits
                </label>
                <input
                  type="text"
                  id="cc_number"
                  name="cc_number"
                  placeholder="1234"
                  maxLength={4}
                  className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={formik.values.cc_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.cc_number && formik.errors.cc_number && (
                  <div className="text-red-600 text-sm mt-1">
                    {formik.errors.cc_number}
                  </div>
                )}
              </div>
              <div className="flex space-x-4 mb-4">
                <div className="w-1/2">
                  <label
                    className="block text-sm font-medium mb-2"
                    htmlFor="expiryMonth"
                  >
                    Expiry Month
                  </label>
                  <input
                    type="text"
                    id="month"
                    name="month"
                    placeholder="05"
                    className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formik.values.month}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.month && formik.errors.month && (
                    <div className="text-red-600 text-sm mt-1">
                      {formik.errors.month}
                    </div>
                  )}
                </div>
                <div className="w-1/2">
                  <label
                    className="block text-sm font-medium mb-2"
                    htmlFor="expiryYear"
                  >
                    Expiry Year
                  </label>
                  <input
                    type="text"
                    id="year"
                    name="year"
                    placeholder="2030"
                    className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formik.values.year}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.year && formik.errors.year && (
                    <div className="text-red-600 text-sm mt-1">
                      {formik.errors.year}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-end space-x-2">
                <ButtonPrimary
                  type="submit"
                  className="mt-2"
                  loading={formik.isSubmitting}
                >
                  {formik.isSubmitting ? "Saving..." : "Save"}
                </ButtonPrimary>
                <button
                  type="button"
                  onClick={closeModal}
                  className="mt-2 px-4 py-2 text-gray-700 dark:text-gray-200 bg-gray-300 dark:bg-gray-700 rounded hover:bg-gray-400 dark:hover:bg-gray-600"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isPaypalModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75">
          <div className="bg-white dark:bg-gray-900 p-6 rounded-lg shadow-lg w-11/12 max-w-md">
            <h2 className="text-2xl font-semibold mb-4">
              {currentUser?.paypal_info?.id ? "Edit PayPal" : "Add PayPal"}
            </h2>

            <Formik
              initialValues={{
                email: currentUser?.paypal_info?.email || "",
              }}
              enableReinitialize
              validationSchema={paypalValidationSchema}
              onSubmit={(values, { setSubmitting }) =>
                handlePayPalSubmit(
                  values,
                  setSubmitting,
                  dispatch,
                  currentUser?.paypal_info?.id || null,
                  closePaypalModal
                )
              }
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="mb-4">
                    <label
                      className="block text-sm font-medium mb-2"
                      htmlFor="email"
                    >
                      PayPal Email
                    </label>
                    <Field
                      type="email"
                      name="email"
                      className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-600 text-sm mt-1"
                    />
                  </div>

                  <div className="flex justify-end space-x-2">
                    <ButtonPrimary
                      type="submit"
                      className="mt-2"
                      loading={isSubmitting}
                    >
                      {isSubmitting ? "Saving..." : "Save"}
                    </ButtonPrimary>
                    <button
                      type="button"
                      onClick={closePaypalModal}
                      className="mt-2 px-4 py-2 text-gray-700 dark:text-gray-200 bg-gray-300 dark:bg-gray-700 rounded hover:bg-gray-400 dark:hover:bg-gray-600"
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentInfo;
