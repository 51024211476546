import { Tab } from "@headlessui/react";
import React, { FC, useState } from "react";
import { GuestsObject } from "components/HeroSearchForm/type";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { fetchPaymentIntent } from "../../redux/reducers/Payments/PaymentReducer";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ReserveBookingResponse } from "redux/reducers/Payments/PaymentReducerTypes";

export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {
  const reserveBooking = useSelector(
    (state: RootState) => state.payment.reserveBooking
  );

  if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
    throw new Error("Stripe in not initialised");
  }
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  const options: StripeElementsOptions = {
    mode: "payment",
    amount: Number(Number(reserveBooking?.booking?.total).toFixed(0)),
    currency: "usd",
    appearance: {},
  };

  const [guests] = useState<GuestsObject>({
    guestAdults: 2,
    guestChildren: 1,
    guestInfants: 1,
  });

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">Confirm payment</h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div className="mt-6">
            <Tab.Group>
              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <Elements stripe={stripePromise} options={options}>
                    <PaymentForm reserveBooking={reserveBooking} />
                  </Elements>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full">{renderMain()}</div>
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;

const PaymentForm = ({
  reserveBooking,
}: {
  reserveBooking: ReserveBookingResponse;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState<boolean>(false);

  const handlePayment = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (elements == null || stripe == null) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError?.message) {
      setLoading(false);
      toast.error(submitError.message, {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }

    try {
      const payload = {
        payment_intent: {
          amount: Number(Number(reserveBooking?.booking?.total).toFixed(0)),
          currency: "USD",
        },
      };
      const action = await dispatch(fetchPaymentIntent(payload));

      if (action?.payload.success) {
        const clientSecret = action.payload.client_secret;

        const { error } = await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url: `${window.location.origin}/success?payment_method=stripe`,
          },
        });

        if (error) {
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      }
    } catch (err) {
      toast.error("Error in confirming payment", {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center p-4 bg-gradient-to-r from-blue-400 to-purple-500">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
        <form onSubmit={handlePayment} className="flex flex-col space-y-4">
          <PaymentElement className="w-full mb-4" />
          <button
            type="submit"
            disabled={!stripe || loading}
            className={`flex items-center justify-center w-full h-12 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg transition duration-300 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {loading ? "Processing..." : "Pay Now"}
          </button>
        </form>
      </div>
    </div>
  );
};
