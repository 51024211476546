import axios from "axios";
import { useNavigate } from "react-router-dom"; 
import { useDispatch } from "react-redux"; 
import { logout } from "../../redux/reducers/AuthSlice/AuthSlice"; // Import your logout action
import ErrorHandler from "./ErrorHandler";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("authToken"); // Fetch token from localStorage
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // Set the Authorization header
    }
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data"; // Set the content type for file uploads
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors globally
API.interceptors.response.use(
  (response) =>  response.data, // Return response data directly
  (error) => {
    const statusCode = error.response?.status;
    if (statusCode === 401 || statusCode === 403) {
      // Handle unauthorized or forbidden (e.g., invalid or expired token)
      console.log("Session expired. Please login again.");
      ErrorHandler("Unauthorized Error!")
      
      // Remove token from localStorage
      localStorage.removeItem("authToken");
      localStorage.clear()
      // window.location.href = '/login';
      setTimeout(()=> {
        window.location.href="/login"
        // window.location.reload()
      }, 2000)
      

      // Optional: Dispatch logout action to clear state
      // const dispatch = useDispatch();
      // dispatch(logout());

      // Navigate to login page
      // const navigate = useNavigate();
      // navigate("/login");
      // window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default API;
