import { Provider } from "react-redux";
import MyRouter from "routers/index";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer stacked={true} />
          <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
            <MyRouter />
          </div>
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
