import NcInputNumberV2 from "components/NcInputNumber/NcInputNumberV2";
import { useField } from "formik";
import { FC } from "react";

interface FormInputNumberProps {
  name: string;
  label: string;
  min?: number;
  max?: number;
  desc?: string;
}

const FormInputNumber: FC<FormInputNumberProps> = ({
  name,
  label,
  min,
  max,
  desc,
}) => {
  const [field, meta, helpers] = useField<number>(name);

  const handleChange = (value: number) => {
    helpers.setValue(value);
    helpers.setTouched(true);
  };

  return (
    <div>
      <NcInputNumberV2
        label={label}
        min={min}
        max={max}
        value={field.value}
        onChange={handleChange}
        desc={desc}
      />
      {meta.touched && meta.error ? (
        <div className="text-red-500 text-sm mt-1">{meta.error}</div>
      ) : null}
    </div>
  );
};
export default FormInputNumber;
