import { useFormik } from "formik";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { useDispatch } from "react-redux";
import successHandler from "../../utils/helpers/SuccessHandler";
import { updateUserAPI } from "../../redux/reducers/UserSlice/UserSlice";
import { AppDispatch } from "redux/store";
import { passwordvalidationSchema } from "../../utils/formSchema";
import { UserResponseBody } from "../../redux/reducers/UserSlice/UserSliceType";

const AccountPass = () => {
  const dispatch = useDispatch<AppDispatch>();

  const formik = useFormik({
    initialValues: {
      new_Password: "",
      confirm_Password: "",
    },

    validationSchema: passwordvalidationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const payload = {
        password: values.new_Password,
      };
      console.log("Updating password with payload:", payload);
      setSubmitting(true);
      dispatch(updateUserAPI({ user: payload }))
        .unwrap()
        .then((response: UserResponseBody) => {
          // Now using UserResponseBody
          if (response) {
            successHandler(response.message || "Password updated successfully");
            resetForm();
          } else {
            console.log("Password update failed: ", response);
          }
        })
        .catch((error: any) => {
          console.log("Error updating password: ", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <div>
      <CommonLayout>
        <form
          onSubmit={formik.handleSubmit}
          className="grid grid-cols-1 gap-6"
          autoComplete="off"
        >
          <div className="space-y-6 sm:space-y-8">
            <h2 className="text-3xl font-semibold">Update your password</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className=" max-w-xl space-y-6">
              <div>
                <label className="block">
                  <span className="mt-1.5">New Password</span>
                  <div className="relative">
                    <Input
                      autoComplete="off"
                      type="password"
                      id="new-password" 
                      name="new_Password"
                      value={formik.values.new_Password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="mt-1.5"
                    />
                    {formik.touched.new_Password &&
                    formik.errors.new_Password ? (
                      <div className="text-red-600 text-sm mt-1">
                        {formik.errors.new_Password}
                      </div>
                    ) : null}
                  </div>
                </label>
              </div>
              <div>
                <label className="block">
                  <span className="mt-1.5">Confirm Password</span>
                  <div className="relative">
                    <Input
                      type="password"
                      autoComplete="off"
                      name="confirm_Password"
                      id="confirm-password"
                      value={formik.values.confirm_Password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.touched.confirm_Password &&
                  formik.errors.confirm_Password ? (
                    <div className="text-red-600 text-sm mt-1">
                      {formik.errors.confirm_Password}
                    </div>
                  ) : null}
                </label>
              </div>
              <div className="pt-2">
                <ButtonPrimary type="submit">Update password</ButtonPrimary>
              </div>
            </div>
          </div>
        </form>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
