import React, { FC, useRef } from "react";

export interface InputFileProps {
  label?: string;
  subLabel?: string;
  className?: string;
  name: string;
  accept?: string;
  multiple?: boolean;
  disabled?: boolean;
  onChange?: (files: FileList | null) => void;
  fileName?: string;
  university_loa_file_name?: string; 
  work_loa_file_name?: string; 
}


const InputFile: FC<InputFileProps> = ({
  label = "",
  subLabel = "",
  className = "",
  name,
  accept = "",
  multiple = false,
  disabled = false,
  onChange,
  fileName, 
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      if (onChange) {
        onChange(files);
      }
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className={`flex gap-x-2 ${className}`}>
      {label && (
        <label htmlFor={name} className="mb-2 text-neutral-900 dark:text-neutral-100">
          {label}
        </label>
      )}
      <div className="relative flex items-center">
        <button
          type="button"
          className="px-4 py-2 bg-primary-500 text-white rounded-lg cursor-pointer hover:bg-primary-600 focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          onClick={handleButtonClick}
        >
          Choose File
        </button>
        <input
          ref={fileInputRef}
          id={name}
          name={name}
          disabled={disabled}
          type="file"
          className="hidden"
          accept={accept}
          multiple={multiple}
          onChange={handleFileChange} 
        />
      </div>
      {subLabel && (
        <p className="mt-1 text-neutral-500 dark:text-neutral-400 text-sm font-light">
          {subLabel}
        </p>
      )}
    </div>
  );
};

export default InputFile;
