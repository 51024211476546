import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { BookingRequestDetails } from "../../redux/reducers/Properties/PropertiesSliceTypes";
import { AppDispatch } from "redux/store";
import { sendBookingInvoice } from "../../redux/reducers/Properties/PropertiesSlice";

interface InvoiceProps {
  bookingRequestDetails: BookingRequestDetails | null;
  closeInvoice: () => void;
}

const Invoice: FC<InvoiceProps> = ({ bookingRequestDetails, closeInvoice }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [expenses, setExpenses] = useState<{ name: string; value: number }[]>(
    []
  );
  const [discounts, setDiscounts] = useState<number[]>([]);
  const [expenseName, setExpenseName] = useState("");
  const [expenseValue, setExpenseValue] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const addExpense = () => {
    setExpenses([...expenses, { name: expenseName, value: expenseValue }]);
    setExpenseName("");
    setExpenseValue(0);
  };

  const addDiscount = () => {
    setDiscounts([...discounts, discountValue]);
    setDiscountValue(0);
  };

  const totalExpenses = expenses.reduce((sum, exp) => sum + exp.value, 0);
  const totalDiscounts = discounts.reduce((sum, disc) => sum + disc, 0);
  const baseRent = 120;
  const total = bookingRequestDetails?.item_total && (Number(bookingRequestDetails?.item_total) + totalExpenses - totalDiscounts)

  const removeDiscount = (index: any) => {
    const updatedDiscounts = [...discounts];
    updatedDiscounts.splice(index, 1);
    setDiscounts(updatedDiscounts);
  };

  const removeExpense = (index: any) => {
    const updatedExpenses = [...expenses];
    updatedExpenses.splice(index, 1);
    setExpenses(updatedExpenses);
  };

  const sendInvoiceHandler = async () => {
    setIsLoading(true);
    const payloadData = {
      bookingId: bookingRequestDetails?.id,
      booking: {
        status: "invoice_sent",
        invoice_attributes: {
          status: "sent",
          billing_type: "one time",
          invoice_type: "Reservation fee",
          expenses_attributes: expenses?.map((x) => ({ category: x.name, amount: x.value })),
          discounts_attributes: discounts?.map((y) => ({ description: "Seasonal discount", amount: y }))
        },
      },
    };
    const action = await dispatch(sendBookingInvoice(payloadData))
    console.log('action', action)
    setIsLoading(false);
    closeInvoice();
  };

  return bookingRequestDetails && (
    <div className="flex p-6 bg-gray-100 justify-center mt-5  ">
      <div className="w-2/3 p-6 border rounded shadow bg-white">
        <h2 className="text-xl font-medium mb-4">Create Invoice</h2>
        <div className="mb-6">
          <div className="flex flex-col space-y-2">
            <p className="font-normal text-sm text-black">
              <strong>Period :</strong>{" "}
              <span className="font-normal text-gray-500">
                {bookingRequestDetails?.check_in_date} to {bookingRequestDetails?.check_out_date}
              </span>
            </p>
            <p className="font-medium text-sm text-black">
              <strong>No. of Months:</strong>{" "}
              <span className="font-normal text-gray-500">1</span>
            </p>
            <p className="font-medium text-sm text-black">
              <strong>No. of guests:</strong>{" "}
              <span className="font-normal text-gray-500">{bookingRequestDetails?.number_of_guests}</span>
            </p>
            <p className="font-medium text-sm text-black">
              <strong>Price per Month:</strong>{" "}
              <span className="font-normal text-gray-500">USD {bookingRequestDetails?.item_total}</span>
            </p>
          </div>
        </div>
        <div>
          <table className="w-full mb-4 ">
            <thead>
              <tr className="border-b">
                <th className="text-left font-medium">Subtotal</th>
                <th className="text-left font-medium">Detail</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b  text-gray-500 text-sm p-6">
                <td className="pt-4 pb-4">USD {bookingRequestDetails?.item_total}</td>
                <td className="pt-4 pb-4">First Month's Rent</td>
              </tr>
              {expenses.map((expense, index) => (
                <tr key={index} className="border-b  text-gray-500 text-sm p-6">
                  <td className="pt-4 pb-4">{expense.name}</td>
                  <td className="pt-4 pb-4">USD {expense.value}</td>
                  <td>
                    <button
                      onClick={() => removeExpense(index)}
                      className="text-gray-400 text-xs"
                    >
                      ✖
                    </button>
                  </td>
                </tr>
              ))}
              {discounts.map((discount, index) => (
                <tr key={index} className="border-b  text-gray-500 text-sm p-6">
                  <td className="pt-4 pb-4">Discount</td>
                  <td className="pt-4 pb-4">USD -{discount}</td>
                  <td>
                    <button
                      onClick={() => removeDiscount(index)}
                      className="text-gray-400 text-xs"
                    >
                      ✖
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-between font-medium">
            <span>Total</span>
            <span>USD {total}</span>
          </div>
        </div>
        <button
          onClick={sendInvoiceHandler}
          className={`mt-6 px-4 py-2 rounded ${isLoading ? 'bg-gray-400' : 'bg-orange-500'} text-white`}
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="flex items-center justify-center">
              <div className="animate-spin border-t-2 border-white border-solid rounded-full w-6 h-6 mr-2"></div>
              Sending...
            </div>
          ) : (
            'Send Invoice'
          )}
        </button>
      </div>

      <div className="w-1/3 ml-6">
        <div className="mb-4">
          <h3 className="text-m font-medium mb-2">Add extra expense</h3>
          <input
            type="text"
            value={expenseName}
            onChange={(e) => setExpenseName(e.target.value)}
            placeholder="type expense name"
            className="border p-2 w-full mb-2 rounded-none"
            style={{ width: "182px" }}
          />
          <div className="flex space-x-2 mb-2">
            <input
              type="number"
              value={expenseValue}
              onChange={(e) => setExpenseValue(Number(e.target.value))}
              placeholder="type expense value"
              className="border p-2 w-full mb-2 rounded-none appearance-none"
              min="0"
            />

            <button
              onClick={addExpense}
              className="px-4 py-2 bg-orange-500 text-white h-full text-sm"
            >
              Add
            </button>
          </div>
        </div>
        <div>
          <h3 className="text-m font-medium mb-2">Add discount</h3>
          <div className="flex space-x-2 mb-2">
            <input
              type="number"
              value={discountValue}
              onChange={(e) => setDiscountValue(Number(e.target.value))}
              placeholder="type discount value"
              className="border p-2 w-full mb-2 rounded-none appearance-none"
              min="0"
            />
            <button
              onClick={addDiscount}
              className="px-4 py-2 bg-orange-500 text-white h-full text-sm"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
