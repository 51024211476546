import React, { FC, Fragment, useEffect, useState } from "react";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "../SectionDateRange";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Amenities_demos, PHOTOS } from "./constant";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import GuestsInput from "./GuestsInput";
import { getallupdateProperties } from '../../../redux/reducers/Properties/PropertiesSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import { useSelector } from 'react-redux';
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";
import { reserveBooking } from "../../../redux/reducers/Payments/PaymentReducer";
import ContactOwnerModal from "./contact-owner";
import successHandler from "utils/helpers/SuccessHandler";
import ErrorHandler from "utils/helpers/ErrorHandler";

const StayDetailPageContainer: FC<{}> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [propertyData, setPropertyData] = useState<PropertyToEdit | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { globalId } = useSelector((state: RootState) => state.property);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const propertyId = globalId || id;
  const totalTax = propertyData?.property_taxes.reduce((acc, tax) => acc + tax.value, 0);
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);


  // const thisPathname = useLocation().pathname;
  const router = useNavigate();

  // const handleChatButtonClick = async () => {
  //   try {
  //     await dispatch(createChat({ sender_id: 3, receiver_id: 1, property_id: 9 })).unwrap(); // `unwrap` throws an error if rejected
  //     router('/messages'); 
  //   } catch (error) {
  //     alert("Failed to create chat. Please try again."); 
  //   }
  // };
  const handleChatButtonClick: () => void = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleSendMessage = (data: any) => {
    console.log('Message sent:', data);
    setIsModalOpen(false);
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  // const handleOpenModalImageGallery = () => {
  //   router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  // };


  useEffect(() => {
    const fetchPropertyData = async () => {
      if (propertyId || id) {
        try {
          setLoading(true);
          const response: any = await dispatch(
            getallupdateProperties({ propertyId })
          );

          // Check for property data in response.payload
          const property = response.payload?.property;
          if (property) {
            setPropertyData(property);
          } else {
            console.error("Property not found:", response);
          }
        } catch (error) {
          console.error('Error fetching property data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPropertyData();
  }, [propertyId, id, dispatch]);


  if (loading) {
    return (
      <div className="flex items-center justify-center py-10">
        <div className="text-neutral-500">Loading property data...</div>
        {/* Optionally, add a spinner or loading animation here */}
      </div>
    );
  }

  const renderSection1 = () => {
    if (!propertyData) return null;

    return (
      <div className="listingSection__wrap !space-y-6">
        <div className="flex justify-between items-center">
          <Badge name={propertyData.property_type?.name || 'Property'} />
          <LikeSaveBtns />
          {propertyData?.user?.is_host && (
            <ButtonSecondary href={`/add-listing-1?id=${propertyData?.id}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              <span className="ml-3">Edit</span>
            </ButtonSecondary>
          )}
        </div>

        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {propertyData.title}
        </h2>

        <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">{propertyData.address}</span>
          </span>
        </div>

        <div className="flex items-center">
          <Avatar
            hasChecked
            sizeClass="h-10 w-10"
            radius="rounded-full"
            imgUrl={propertyData.user?.image}
          />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {`${propertyData.user?.first_name} ${propertyData.user?.last_name}`}
            </span>
          </span>
        </div>

        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3">
            <i className="las la-user text-2xl"></i>
            <span>
              {propertyData.guest_number}{" "}
              <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-door-open text-2xl"></i>
            <span>
              {propertyData.total_rooms}{" "}
              <span className="hidden sm:inline-block">rooms</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-bath text-2xl"></i>
            <span>
              {propertyData.total_bathrooms}{" "}
              <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-expand-arrows-alt text-2xl"></i>
            <span>
              {propertyData.property_size}{" "}
              <span className="hidden sm:inline-block">sq ft</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    if (!propertyData) return null;

    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Property Description</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>{propertyData.description}</span>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    if (!propertyData) return null;
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Features & Amenities</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` About the property's amenities and services`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        {/* <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {Amenities_demos.filter((_, i) => i < 15).map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div> */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300">
          {propertyData.features.filter((_, i) => i < 3).map((feature) => (
            <div key={feature.id} className="flex items-center space-x-3">
              <i className="text-3xl las la-check-circle"></i>
              <span>{feature.name}</span>
            </div>
          ))}
          {propertyData.amenities.filter((_, i) => i < 3).map((amenities) => (
            <div key={amenities.id} className="flex items-center space-x-3">
              <i className="text-3xl las la-check-circle"></i>
              <span>{amenities.name}</span>
            </div>
          ))}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View more amenities
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    if (!propertyData) return null;
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {propertyData.features.map((feature) => (
                      <div key={feature.id} className="flex items-center space-x-3 p-3">
                        <i className="text-3xl las la-check-circle"></i>
                        <span>{feature.name}</span>
                      </div>
                    ))}
                    {propertyData.amenities.map((amenities) => (
                      <div key={amenities.id} className="flex items-center space-x-3 p-3">
                        <i className="text-3xl las la-check-circle"></i>
                        <span>{amenities.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  // const renderSection4 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <div>
  //         <h2 className="text-2xl font-semibold">Room Rates </h2>
  //         <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
  //           Prices may increase on weekends or holidays
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       {/* CONTENT */}
  //       <div className="flow-root">
  //         <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
  //           <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Monday - Thursday</span>
  //             <span>$199</span>
  //           </div>
  //           <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Monday - Thursday</span>
  //             <span>$199</span>
  //           </div>
  //           <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Friday - Sunday</span>
  //             <span>$219</span>
  //           </div>
  //           <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Rent by month</span>
  //             <span>-8.34 %</span>
  //           </div>
  //           <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Minimum number of nights</span>
  //             <span>1 night</span>
  //           </div>
  //           <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
  //             <span>Max number of nights</span>
  //             <span>90 nights</span>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderSection5 = () => {
    if (!propertyData) return null;
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            sizeClass="h-10 w-10"
            radius="rounded-full"
            imgUrl={propertyData.user?.image}
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              <span className="text-neutral-900 dark:text-neutral-200 font-medium">
                {`${propertyData.user?.first_name} ${propertyData.user?.last_name}`}
              </span>
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              {/* <span className="mx-2">·</span>
              <span> 12 places</span> */}
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor swimming pool, a bar, a shared lounge, a
          garden and barbecue facilities...
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="/author">See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            San Diego, CA, United States of America (SAN-San Diego Intl.)
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY&q=Eiffel+Tower,Paris+France"
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Refund 50% of the booking value when customers cancel the room
            within 48 hours after successful booking and 14 days before the
            check-in time. <br />
            Then, cancel the room 14 days before the check-in time, get a 50%
            refund of the total amount paid (minus the service fee).
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>08:00 am - 12:00 am</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>02:00 pm - 04:00 pm</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };
  const renderSection9 = () => {
    if (!propertyData) return null;
    const handleSubmit = async (room: any) => {
      setLoading(true);
      const postData = {
        booking: {
          number_of_guests: propertyData?.guest_number,
          property_id: propertyId,
          check_in_date: propertyData?.availability_start,
          check_out_date: propertyData?.availability_end,
          line_items_attributes: [
            {
              room_id: room.id,
              price: propertyData?.rooms[0].price_per_night,
              quantity: 1,
              property_id: propertyId,
            },
          ],
        },
      };

      try {
        const action = await dispatch(reserveBooking(postData as any));
        if (action?.type === 'payment/reserveBooking/fulfilled' && action.payload) {
          successHandler("Booking request sent. Please wait for owner's confirmation!!");
        }
      } catch (error) {
        ErrorHandler("Failed to reserve booking. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    return (
      <div className="listingSection__wrap !space-y-10">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">Rooms</h2>
        {propertyData.rooms.map((room, index) => (
          <div key={room.id} className="mb-16"> {/* Increased margin-bottom for spacing between rooms */}
            <div className="flex justify-between items-center mb-4"> {/* Added margin-bottom */}
              <Badge name={room.status || 'Property'} />
            </div>

            <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300 mb-8"> {/* Added margin-bottom */}
              <div className="flex items-center space-x-3">
                <i className="las la-user text-2xl"></i>
                <span>{room.max_guests || 'Default Value'}</span>
              </div>
              <div className="flex items-center space-x-3">
                <i className="las la-door-open text-2xl"></i>
                <span>{room.room_type?.name || 'Room Type'}</span>
              </div>
              <div className="flex items-center space-x-3">
                <i className="las la-bed text-2xl"></i>
                <span>{room.bed_type?.name || 'Bed Type'}</span>
              </div>
              <div className="flex items-center space-x-3">
                <i className="las la-expand-arrows-alt text-2xl"></i>
                <span>{room.size} sq ft</span>
              </div>
            </div>

            <div className="listingSection__wrap mb-8"> {/* Added margin-bottom */}
              <h2 className="text-2xl font-semibold">Room Description</h2>
              <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-4"></div>
              <div className="text-neutral-6000 dark:text-neutral-300">
                <span>{room.description}</span>
              </div>
            </div>

            <div className="mb-8">
              <SectionDateRange
                availabilityStart={new Date(room.booking_start || Date.now()).getTime()}
                availabilityEnd={new Date(room.booking_end || Date.now()).getTime()}
              />
            </div>

            <div className="listingSection__wrap mb-8"> {/* Added margin-bottom */}
              <div>
                <h2 className="text-2xl font-semibold">Rooms Features & Amenities</h2>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  About the property's amenities and services
                </span>
              </div>
              <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-6"></div>

              <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300">
                {room.features.map((feature) => (
                  <div key={feature.id} className="flex items-center space-x-3">
                    <i className="text-3xl las la-check-circle"></i>
                    <span>{feature.name}</span>
                  </div>
                ))}
                {room.amenities.slice(0, 3).map((amenity) => (
                  <div key={amenity.id} className="flex items-center space-x-3">
                    <i className="text-3xl las la-check-circle"></i>
                    <span>{amenity.name}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="listingSectionSidebar__wrap shadow-xl mb-10"> {/* Added margin-bottom */}
              <div className="flex justify-between">
                <span className="text-3xl font-semibold">
                  {room.price_per_night}
                  <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                    /night
                  </span>
                </span>
                <StartRating />
              </div>

              <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl">
                <StayDatesRangeInput className="flex-1 z-[11]" startDates = {room.booking_start}  endDates = {room.booking_end} />
                <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                <GuestsInput className="flex-1" guestNumber={room.max_guests} />
              </form>

              <div className="flex flex-col space-y-4">
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>{room.price_per_night}</span>
                  <span>{room.price_per_night}</span>
                </div>
                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex justify-between font-semibold">
                  <span>Total</span>
                  <span>{room.price_per_night}</span>
                </div>
              </div>
              <ButtonPrimary onClick={() => handleSubmit(room)}>
                {loading ? "Processing..." : "Reserve"}
              </ButtonPrimary>
              <ButtonPrimary onClick={handleChatButtonClick}>Contact Owner</ButtonPrimary>
              {isModalOpen && (
                <ContactOwnerModal
                  isOpen={isModalOpen}
                  onClose={handleCloseModal}
                  onSend={handleSendMessage}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };


  // const renderSidebar = () => {
  //   if (!propertyData) return null;

  //   return (
  //     <div className="listingSectionSidebar__wrap shadow-xl">
  //       {/* PRICE */}
  //       <div className="flex justify-between">
  //         <span className="text-3xl font-semibold">
  //           {propertyData?.price_per_night}
  //           {` `}
  //           <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
  //             /night
  //           </span>
  //         </span>
  //         <StartRating />
  //       </div>

  //       {/* FORM */}
  //       <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
  //         <StayDatesRangeInput className="flex-1 z-[11]" />
  //         <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
  //         <GuestsInput className="flex-1" guestNumber={propertyData?.guest_number} />

  //       </form>

  //       {/* SUM */}
  //       <div className="flex flex-col space-y-4">
  //         <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
  //           <span>{propertyData?.price_per_night}</span>
  //           <span>{propertyData?.price_per_night}</span>
  //         </div>
  //         <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
  //           <span>Service charge</span>
  //           <span>{totalTax}</span>
  //         </div>
  //         <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
  //         <div className="flex justify-between font-semibold">
  //           <span>Total</span>
  //           <span>{(
  //             (parseFloat(propertyData?.price_per_night) || 0) +
  //             (propertyData?.property_taxes.reduce((acc, tax) => acc + tax.value, 0) || 0)
  //           )}</span>
  //         </div>
  //       </div>

  //       {/* SUBMIT */}
  //       <ButtonPrimary onClick={() => {}}>
  //         {loading ? "Processing..." : "Reserve"}
  //       </ButtonPrimary>
  //       <ButtonPrimary onClick={handleChatButtonClick}>Contact Owner</ButtonPrimary>
  //       {isModalOpen && (
  //         <ContactOwnerModal
  //           isOpen={isModalOpen}
  //           onClose={handleCloseModal}
  //           onSend={handleSendMessage}
  //           propertyData={propertyData}
  //         />
  //       )}

  //     </div>
  //     // <div className="listingSectionSidebar__wrap shadow-xl">
  //     //   {/* PRICE */}
  //     //   <div className="flex justify-between">
  //     //     <span className="text-3xl font-semibold">
  //     //       {propertyData.rooms?.[0]?.price_per_night}
  //     //       {` `}
  //     //       <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
  //     //         /night
  //     //       </span>
  //     //     </span>
  //     //     <StartRating />
  //     //   </div>

  //     //   {/* FORM */}
  //     //   <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
  //     //     <StayDatesRangeInput className="flex-1 z-[11]" />
  //     //     <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
  //     //     <GuestsInput className="flex-1" guestNumber={propertyData.rooms?.[0]?.max_guests} />

  //     //   </form>

  //     //   {/* SUM */}
  //     //   <div className="flex flex-col space-y-4">
  //     //     <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
  //     //       <span>{propertyData.rooms?.[0]?.price_per_night}</span>
  //     //       <span>{propertyData.rooms?.[0]?.price_per_night}</span>
  //     //     </div>
  //     //     {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
  //     //       <span>Service charge</span>
  //     //       <span>{totalTax}</span>
  //     //     </div> */}
  //     //     <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
  //     //     <div className="flex justify-between font-semibold">
  //     //       <span>Total</span>
  //     //       {/* <span>{(
  //     //         (parseFloat(propertyData?.price_per_night) || 0) +
  //     //         (propertyData?.property_taxes.reduce((acc, tax) => acc + tax.value, 0) || 0)
  //     //       )}</span> */}
  //     //       <span>{propertyData.rooms?.[0]?.price_per_night}</span>
  //     //     </div>
  //     //   </div>

  //     //   {/* SUBMIT */}
  //     //   {/* <ButtonPrimary onClick={handleSubmit}>
  //     //     {loading ? "Processing..." : "Reserve"}
  //     //   </ButtonPrimary> */}
  //     //   <ButtonPrimary onClick={handleChatButtonClick}>Contact Owner</ButtonPrimary>
  //     //   {isModalOpen && (
  //     //     <ContactOwnerModal
  //     //       isOpen={isModalOpen}
  //     //       onClose={handleCloseModal}
  //     //       onSend={handleSendMessage}
  //     //     />
  //     //   )}

  //     // </div>
  //   );
  // };

  if (!propertyData) return null;
  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
          // onClick={handleOpenModalImageGallery}
          >
            <img
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={propertyData.place_images[0]}
              alt=""
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />

            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {propertyData.place_images.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
            <div
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                }`}
            >
              <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                <img
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={item || ""}

                  alt=""
                  sizes="400px"
                />
              </div>

              {/* OVERLAY */}
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
              // onClick={handleOpenModalImageGallery}
              />
            </div>
          ))}

          <button
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
          // onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="w-5 h-5" />
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Show all photos
            </span>
          </button>
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection3()}
          {/* {renderSection4()} */}
          <SectionDateRange
            availabilityStart={new Date(propertyData?.availability_start || Date.now()).getTime()}
            availabilityEnd={new Date(propertyData?.availability_end || Date.now()).getTime()}
          />


          {renderSection5()}
          {renderSection6()}
          {renderSection7()}
          {renderSection8()}
          {renderSection9()}

        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          {/* <div className="sticky top-28">{renderSidebar()}</div> */}
        </div>
      </main>
    </div>
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
