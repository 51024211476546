import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import API from "../../../utils/helpers/APIHelper";
import { RootState } from "../../store";
import ErrorHandler from "../../../utils/helpers/ErrorHandler";
import { ENDPOINTS } from "../../../API/apiConstants";
import {
  BookingRequestPayload,
  ConfirmBookingResponse,
  PaymentIntent,
  PaymentMethodsResponse,
  PaymentSucessRequestPayload,
  ReserveBookingResponse,
} from "./PaymentReducerTypes";

type PaymentInitialState = {
  loading: boolean;
  paymentIntent: null;
  reserveBooking: ReserveBookingResponse;
};

const initialState: PaymentInitialState = {
  loading: false,
  paymentIntent: null,
  reserveBooking: {
    booking: null,
    booking_query: "",
    success: false,
  },
};

export const fetchPaymentIntent = createAsyncThunk<
  any,
  PaymentIntent,
  { state: RootState; rejectValue: any }
>("payment/fetchPaymentIntent", async (payload, { rejectWithValue }) => {
  try {
    const response = await API.post(ENDPOINTS.PAYMET_INTENT, payload);
    return response;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const reserveBooking = createAsyncThunk<
  ReserveBookingResponse,
  BookingRequestPayload,
  { state: RootState; rejectValue: any }
>("payment/reserveBooking", async (payload, { rejectWithValue }) => {
  try {
    const response = await API.post(ENDPOINTS.BOOKING, payload);
    return response.data;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const paymentSuccess = createAsyncThunk<
  ConfirmBookingResponse,
  PaymentSucessRequestPayload,
  { state: RootState; rejectValue: any }
>("payment/paymentSuccess", async (payload, { getState, rejectWithValue }) => {
  const booking = getState().payment.reserveBooking.booking;
  const bookingId = booking && booking.id ? booking.id : 0;
  try {
    const response = await API.put(
      ENDPOINTS.PAYMENT_SUCCESS(bookingId),
      payload
    );
    return response.data;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const getPaymentMethods = createAsyncThunk<
  PaymentMethodsResponse,
  void,
  { state: RootState; rejectValue: any }
>("payment/getPaymentMethods", async (_, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.PAYMENT_METHODS);
    return response;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

export const updateBookingsDetails = createAsyncThunk<
  any,
  { id?: string | number; status: string },
  { state: RootState; rejectValue: any }
>("properties/bookings", async ({ id, status }, { rejectWithValue }) => {
  try {
    const response = await API.put(`${ENDPOINTS.GET_BOOKING_DEATAILS}/${id}`, {
      booking: { status },
    });
    return response.data;
  } catch (error) {
    ErrorHandler(error);
    return rejectWithValue(error);
  }
});

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(reserveBooking.fulfilled, updateBookingsDetails.fulfilled),
      (state, action) => {
        state.loading = false;
        state.reserveBooking = action.payload;
      }
    );
    builder.addMatcher(
      (action) => [reserveBooking.pending.type].includes(action.type),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      (action) => [reserveBooking.rejected.type].includes(action.type),
      (state) => {
        state.loading = false;
      }
    );
  },
});

export const {} = paymentSlice.actions;

export default paymentSlice.reducer;
