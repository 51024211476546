import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Custom Success Handler
export default function InfoHandler(message: string, autoClose = 5000) {
  toast.info(message, {
    position: 'top-right',
    autoClose: autoClose, // Default auto close time is 5000 ms, can be customized
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}
