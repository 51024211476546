import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import { Property } from "redux/reducers/Properties/PropertiesSliceTypes";
import { getallPropertieslisting } from "../../../redux/reducers/Properties/PropertiesSlice";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridFeaturePlaces from "containers/PageHome/SectionGridFeaturePlaces";

const MyListing: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [allData, setAllData] = useState<StayDataType[]>([]);
    const [pagemetaData, setMetaData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);

    const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

    const { loading } = useSelector(
        (state: RootState) => state.property
    );

    const getRandomIndex = () => Math.floor(Math.random() * 8);

    const mergedData = (dataToBeMerged: Property[]) => {
        const newData = dataToBeMerged?.map((apiItem) => {
            const randomIndex = getRandomIndex();
            const fallback = DEMO_DATA[randomIndex];
            return {
                ...fallback,
                id: apiItem?.id,
                title: apiItem?.title || fallback?.title,
                place_images: apiItem?.place_images,
                address: apiItem?.address,
                availability_start: apiItem.availability_start || fallback?.date,
                availability_end: apiItem.availability_end || fallback?.date,
                price: fallback?.price,
                href: `/listing-stay-detail?id=${apiItem?.id}`
            };
        });
        setAllData(newData);
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const res = await dispatch(getallPropertieslisting(1)).unwrap();
                mergedData(res?.properties);
                setMetaData(res.meta);
            } catch (error) {
                console.error("Error fetching properties:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch]);

    if (isLoading) {
        return (
            <div className="nc-PageHome relative overflow-hidden">
                <div className="container relative">
                    <div className="min-h-screen flex items-center justify-center">
                        <div className="relative">
                            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-primary-500"></div>
                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-b-4 border-secondary-500"></div>
                            </div>
                        </div>
                        <div className="absolute mt-24 text-center">
                            <p className="text-lg font-semibold text-neutral-900 dark:text-neutral-200 mt-8">
                                Loading listings...
                            </p>
                            <p className="text-sm text-neutral-500 dark:text-neutral-400 mt-2">
                                Please wait while we fetch the properties
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!isLoading && allData.length === 0) {
        return (
            <div className="nc-PageHome relative overflow-hidden">
                <div className="container relative">
                    <div className="min-h-screen flex items-center justify-center">
                        <div className="text-center">
                            <h2 className="text-2xl font-bold text-neutral-900 dark:text-neutral-100">
                                No Listings Found
                            </h2>
                            <p className="mt-2 text-neutral-500 dark:text-neutral-400">
                                There are currently no properties listed.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
                <div className="relative py-16">
                    <BackgroundSection />
                    <SectionGridFeaturePlaces
                        stayListings={allData}
                        pagemetaData={pagemetaData}
                    />
                </div>
            </div>
        </div>
    );
};

export default MyListing;